import { SemanticICONS } from 'semantic-ui-react';

interface Browsers {
  [key: string]: Browser
}

interface Browser {
  name: string;
  icon: SemanticICONS;
}

const browsers: Browsers = {
  chrome: { name: 'Chrome', icon: 'chrome' },
  safari: { name: 'Safari', icon: 'safari' },
  edge: { name: 'Edge', icon: 'edge' },
  firefox: { name: 'Firefox', icon: 'firefox' },
  opera: { name: 'Opera', icon: 'opera' },
  internetExplorer: { name: 'Internet Explorer', icon: 'internet explorer' },
}

export default browsers;
