import { SemanticICONS } from 'semantic-ui-react';

interface Tools {
  [key: string]: Tool
}

interface Tool {
  name: string;
  icon: SemanticICONS | '';
}

const tools: Tools = {
  keyboard: { name: 'Keyboard', icon: 'keyboard' },
  mouse: { name: 'Mouse', icon: 'mouse pointer' },
  pc: { name: 'PC', icon: 'desktop' },
  laptop: { name: 'Laptop', icon: 'laptop' },
  tablet: { name: 'Tablet', icon: 'tablet alternate' },
  smartphone: { name: 'Smartphone', icon: 'mobile alternate' },
  touch: { name: 'Touch', icon: 'hand paper' },

  gamepad: { name: 'Gamepad', icon: 'gamepad' },
  xbox360: { name: 'Xbox 360 Controller', icon: 'gamepad' },

  oculusQuest: { name: 'Oculus Quest', icon: '' },
  
  camera: { name: 'Camera', icon: 'camera' },
  audio: { name: 'Audio', icon: 'volume up' },
  microphone: { name: 'Microphone', icon: 'microphone' },
  headphones: { name: 'Headphones', icon: 'headphones' },

  pen: { name: 'Pen', icon: 'pencil alternate' },
  penAndPaper: { name: 'Pen and Paper', icon: 'edit outline' },

  internet: { name: 'Internet', icon: 'wifi' },
  bluetooth: { name: 'Bluetooth', icon: 'bluetooth' },
  email: { name: 'Email', icon: 'envelope' },
  cloud: { name: 'Cloud', icon: 'cloud' },
  server: { name: 'Server', icon: 'server' },
  gps: { name: 'GPS', icon: 'map marker alternate' },

  terminal: { name: 'Terminal', icon: 'terminal' },
}

export default tools;
