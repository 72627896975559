export const capitalize = (s: string) => {
  return s[0].toUpperCase() + s.slice(1);
};

export const uncapitalize = (s: string) => {
  return s[0].toLowerCase() + s.slice(1);
};

export const removeSpaces = (s: string) => {
  return s.replace(/\s/g, '');
};

export const removeSpacesURL = (s: string) => {
  return s.replaceAll('%20', '').replaceAll('+', '');
};

// From: https://stackoverflow.com/questions/7225407/convert-camelcasetext-to-title-case-text#7225450
export const toTitleCase = (s: string) => {
  const result = s.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
};
