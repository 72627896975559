// From: https://react.semantic-ui.com/views/item/#variations-link
import { useEffect, useRef, Fragment } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Segment, Tab, Card, Header, Image } from 'semantic-ui-react';
import { capitalize, uncapitalize, removeSpaces, removeSpacesURL } from '../ts/helper/StringUtils';
import technologies from '../ts/Technologies';
import '../css/Technologies.css';

interface TechnologySection {
  name: string;
  panes?: TechnologyGroup[];
}

interface TechnologyGroup {
  name: string;
  panes?: Technology[];
}

export interface Technology {
  name: string;
  image: string;
  link: string;
}

const panes: TechnologySection[] = [
  { name: 'Programming', panes: [
    { name: 'Languages', panes: [
      technologies.typeScript,
      technologies.javaScript,
      technologies.html5,
      technologies.css3,
      technologies.php,
      technologies.cSharp,
      technologies.java,
      technologies.python,
      technologies.bash,
    ]},
    { name: 'Data', panes: [
      technologies.mySQL,
      technologies.postgreSQL,
    ]},
    { name: 'Frameworks', panes: [
      technologies.react,
      technologies.mediaPipe,
    ]},
    { name: 'Libraries', panes: [
      technologies.jQuery,
      technologies.d3,
      technologies.bootstrap,
      technologies.semanticUI,
      technologies.selenium,
      technologies.openCV,
      technologies.tensorflow,
      technologies.keras,
    ]},
  ]},
  { name: 'Tools', panes: [
    { name: 'IDEs', panes: [
      technologies.vsCode,
      technologies.visualStudo,
      technologies.eclipse,
      technologies.colaboratory,
    ]},
    { name: 'Version Control', panes: [
      technologies.git,
      technologies.plasticSCM,
    ]},
    { name: 'Other', panes: [
      technologies.unity,
      technologies.gameMaker,
      technologies.vuforia,
      technologies.webpack,
      technologies.jekyll,
      technologies.chatGPT,
    ]},
  ]},
  { name: 'Design', panes: [
    { name: 'Graphics', panes: [
      technologies.photoshop,
      technologies.illustrator,
    ]},
    { name: 'Music', panes: [
      technologies.flStudio,
      technologies.museScore,
      technologies.audition,
    ]},
    { name: 'Video', panes: [
      technologies.camtasia,
      technologies.snagit,
    ]},
    { name: 'Text', panes: [
      technologies.notion,
      technologies.obsidian,
      technologies.overleaf,
      technologies.word,
      technologies.notepadPlusPlus,
    ]},
  ]}
];

const Technologies = ({basepath, basename}: {basepath: string, basename: string}) => {
  const tabRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();

  const onTabChange = (e, { activeIndex }) => {
    if (activeIndex === -1) activeIndex = 0;
    const technologySectionName = panes[activeIndex].name;
    navigate(removeSpaces('/' + basepath + '/' + uncapitalize(technologySectionName)), { replace: true });
    document.title = capitalize(technologySectionName) + ' | ' + basename;
    tabRef.current.state.activeIndex = activeIndex;
  };

  const navigation = (redirect: boolean) => {
    let index = tabRef.current.state.activeIndex;
    if (redirect) {
      const fullPathname = location.pathname;
      const pane = fullPathname.split('/')[2];
      if (pane) {
        index = panes.findIndex(({ name }) => removeSpaces(name.toLowerCase()) === removeSpacesURL(pane.toLowerCase()));
      }
    }
    onTabChange(null, { activeIndex: index } );
  }

  useEffect(() => {
    navigation(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Segment compact attached>
      <Tab ref={tabRef} onTabChange={onTabChange} panes={panes.map((technologySection) => (
        { menuItem: technologySection.name, render: () => (
          <Tab.Pane>
            {technologySection.panes.map((technologyGroup, index) => (
              <Fragment key={index}>
                <Header key={technologyGroup.name} size="large">{technologyGroup.name}</Header>
                <Card.Group>
                  {technologyGroup.panes.map((technology, index) => (
                    <Fragment key={index}>
                      <Card key={technology.name} className="technology-image" href={technology.link} target="_blank" rel="noreferrer">
                        <Image src={technology.image} />
                        <Card.Content>
                          <Header size="small" textAlign="center">{technology.name}</Header>
                        </Card.Content>
                      </Card>
                    </Fragment>
                  ))}
                </Card.Group>
              </Fragment>
            ))}
          </Tab.Pane>
        )}
      ))} />
    </Segment>
  );
};

export default Technologies;
