// Programming
import imageTypescript from '../images/technologies/programming/languages/typescript-logo.png';
import imageJavascript from '../images/technologies/programming/languages/javascript-logo.png';
import imageHTML5 from '../images/technologies/programming/languages/html5-logo.png';
import imageCSS3 from '../images/technologies/programming/languages/css3-logo.png';
import imagePHP from '../images/technologies/programming/languages/php-logo.png';
import imageCSharp from '../images/technologies/programming/languages/c-sharp-logo.png';
import imageJava from '../images/technologies/programming/languages/java-logo.png';
import imagePython from '../images/technologies/programming/languages/python-logo.png';
import imageBash from '../images/technologies/programming/languages/bash-logo.png';

import imageMySQL from '../images/technologies/programming/data/my-sql-logo.png';
import imagePostgreSQL from '../images/technologies/programming/data/postgresql-logo.png';

import imageReact from '../images/technologies/programming/frameworks/react-logo.png';
import imageMediaPipe from '../images/technologies/programming/frameworks/mediapipe-logo.png';

import imageJQuery from '../images/technologies/programming/libraries/jquery-logo.png';
import imageD3 from '../images/technologies/programming/libraries/d3-logo.png';
import imageBootstrap from '../images/technologies/programming/libraries/bootstrap-logo.png';
import imageSemanticUI from '../images/technologies/programming/libraries/semantic-ui-logo.png';
import imageSelenium from '../images/technologies/programming/libraries/selenium-logo.png';
import imageOpenCV from '../images/technologies/programming/libraries/openCV-logo.png';
import imageTensorflow from '../images/technologies/programming/frameworks/tensorflow-logo.png';
import imageKeras from '../images/technologies/programming/libraries/keras-logo.png';

// Tools
import imageVSCode from '../images/technologies/tools/IDEs/vs-code-logo.png';
import imageVisualStudio from '../images/technologies/tools/IDEs/visual-studio-logo.png';
import imageEclipse from '../images/technologies/tools/IDEs/eclipse-logo.png';
import imageColaboratoryLogo from '../images/technologies/tools/IDEs/colaboratory-logo.png';

import imageGit from '../images/technologies/tools/versionControl/git-logo.png';
import imagePlasticSCM from '../images/technologies/tools/versionControl/plastic-scm-logo.png';

import imageUnity from '../images/technologies/tools/other/unity-logo.png';
import imageGameMaker from '../images/technologies/tools/other/gamemaker-logo.png';
import imageGameMakerStudio from '../images/technologies/tools/other/gamemaker-studio-logo.png';
import imageGameMakerStudio2 from '../images/technologies/tools/other/gamemaker-studio-2-logo.png';
import imageVuforia from '../images/technologies/tools/other/vuforia-logo.png';
import imageWebpack from '../images/technologies/tools/other/webpack-logo.png';
import imageBrowserify from '../images/technologies/tools/other/browserify-logo.png';
import imageJekyll from '../images/technologies/tools/other/jekyll-logo.png';
import imageChatGPT from '../images/technologies/tools/other/chatGPT-logo.png';

// Design
import imagePhotoshop from '../images/technologies/design/graphics/ps-logo.png';
import imageIllustrator from '../images/technologies/design/graphics/ai-logo.png';

import imageFLStudio from '../images/technologies/design/music/fl-studio-logo.png';
import imageMusescore from '../images/technologies/design/music/musescore-logo.png';
import imageAudition from '../images/technologies/design/music/au-logo.png';

import imageCamtasia from '../images/technologies/design/video/camtasia-logo.png';
import imageSnagit from '../images/technologies/design/video/snagit-logo.png';

import imageNotion from '../images/technologies/design/text/notion-logo.png';
import imageObsidian from '../images/technologies/design/text/obsidian-logo.png';
import imageOverleaf from '../images/technologies/design/text/overleaf-logo.png';
import imageWord from '../images/technologies/design/text/word-logo.png';
import imageNotepadPlusPlus from '../images/technologies/design/text/notepad-plus-plus-logo.png';

export interface Technologies {
  [key: string]: Technology
}

interface Technology {
  name: string;
  image: string;
  link: string;
}

export const technologies: Technologies = {
  // Programming

  // Languages
  typeScript: { name: 'TypeScript', image: imageTypescript, link: 'https://www.typescriptlang.org/' },
  javaScript: { name: 'JavaScript', image: imageJavascript, link: 'https://developer.mozilla.org/en/docs/Web/JavaScript' },
  html5: { name: 'HTML5', image: imageHTML5, link: 'https://html5.org/' },
  css3: { name: 'CSS3', image: imageCSS3, link: 'https://www.w3.org/Style/CSS/' },
  php: { name: 'PHP', image: imagePHP, link: 'https://www.php.net/' },
  cSharp: { name: 'C#', image: imageCSharp, link: 'https://dotnet.microsoft.com/en-us/languages/csharp' },
  java: { name: 'Java', image: imageJava, link: 'https://www.oracle.com/java/' },
  python: { name: 'Python', image: imagePython, link: 'https://www.python.org/' },
  bash: { name: 'Bash', image: imageBash, link: 'https://www.gnu.org/software/bash/' },
  // Data
  mySQL: { name: 'MySQL', image: imageMySQL, link: 'https://www.mysql.com' },
  postgreSQL: { name: 'PostgreSQL', image: imagePostgreSQL, link: 'https://www.postgresql.org/' },
  // Frameworks
  react: { name: 'React', image: imageReact, link: 'https://reactjs.org/' },
  mediaPipe: { name: 'MediaPipe', image: imageMediaPipe, link: 'https://mediapipe.dev/' },
  // Libraries
  jQuery: { name: 'JQuery', image: imageJQuery, link: 'https://jquery.com/' },
  d3: { name: 'D3', image: imageD3, link: 'https://d3js.org/' },
  bootstrap: { name: 'Bootstrap', image: imageBootstrap, link: 'https://getbootstrap.com/' },
  semanticUI: { name: 'Semantic UI', image: imageSemanticUI, link: 'https://semantic-ui.com/' },
  selenium: { name: 'Selenium', image: imageSelenium, link: 'https://www.selenium.dev/' },
  openCV: { name: 'OpenCV', image: imageOpenCV, link: 'https://opencv.org/' },
  tensorflow: { name: 'Tensorflow', image: imageTensorflow, link: 'https://www.tensorflow.org/' },
  // APIs
  keras: { name: 'Keras', image: imageKeras, link: 'https://keras.io/' },

  // Tools

  // IDEs
  vsCode: { name: 'VS Code', image: imageVSCode, link: 'https://code.visualstudio.com/' },
  visualStudo: { name: 'Visual Studio', image: imageVisualStudio, link: 'https://visualstudio.microsoft.com/' },
  eclipse: { name: 'Eclipse', image: imageEclipse, link: 'https://eclipseide.org/release/' },
  colaboratory: { name: 'Colaboratory', image: imageColaboratoryLogo, link: 'https://colab.research.google.com/' },
  // Version Control
  git: { name: 'Git', image: imageGit, link: 'https://git-scm.com/' },
  plasticSCM: { name: 'Plastic SCM', image: imagePlasticSCM, link: 'https://www.plasticscm.com/' },
  // Other
  unity: { name: 'Unity', image: imageUnity, link: 'https://unity.com/' },
  gameMaker: { name: 'Game Maker', image: imageGameMaker, link: 'https://gamemaker.io/en/gamemaker' },
  gameMakerStudio: { name: 'GM Studio', image: imageGameMakerStudio, link: 'https://gamemaker.io/en/gamemaker' },
  gameMakerStudio2: { name: 'GM Studio 2', image: imageGameMakerStudio2, link: 'https://gamemaker.io/en/gamemaker' },
  vuforia: { name: 'Vuforia', image: imageVuforia, link: 'https://www.ptc.com/en/products/vuforia/vuforia-engine' },
  webpack: { name: 'Webpack', image: imageWebpack, link: 'https://webpack.js.org/' },
  browserify: { name: 'Browserify', image: imageBrowserify, link: 'https://browserify.org/' },
  jekyll: { name: 'Jekyll', image: imageJekyll, link: 'https://jekyllrb.com/' },
  chatGPT: { name: 'ChatGPT', image: imageChatGPT, link: 'https://openai.com/blog/chatgpt' },

  // Design

  // Graphics
  photoshop: { name: 'Photoshop', image: imagePhotoshop, link: 'https://www.adobe.com/products/photoshop.html' },
  illustrator: { name: 'Illustrator', image: imageIllustrator, link: 'https://www.adobe.com/products/illustrator.html' },
  // Music
  flStudio: { name: 'FL Studio', image: imageFLStudio, link: 'https://www.image-line.com/' },
  museScore: { name: 'MuseScore', image: imageMusescore, link: 'https://musescore.org/en' },
  audition: { name: 'Audition', image: imageAudition, link: 'https://www.adobe.com/products/audition.html' },
  // Video
  camtasia: { name: 'Camtasia', image: imageCamtasia, link: 'https://www.techsmith.com/video-editor.html' },
  snagit: { name: 'Snagit', image: imageSnagit, link: 'https://www.techsmith.com/screen-capture.html' },
  // Text
  notion: { name: 'Notion', image: imageNotion, link: 'https://www.notion.so/product' },
  obsidian: { name: 'Obsidian', image: imageObsidian, link: 'https://obsidian.md/' },
  overleaf: { name: 'Overleaf', image: imageOverleaf, link: 'https://www.overleaf.com/' },
  word: { name: 'Word', image: imageWord, link: 'https://www.microsoft.com/en-us/microsoft-365/word' },
  notepadPlusPlus: { name: 'Notepad++', image: imageNotepadPlusPlus, link: 'https://notepad-plus-plus.org/' },
}

export default technologies;
