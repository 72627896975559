import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Segment, Header, Icon } from 'semantic-ui-react';
import { removeSpaces, toTitleCase } from '../ts/helper/StringUtils';

const PageNotFound = ({basepath, basename}: {basepath: string, basename: string}) => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(removeSpaces('/' + basepath), { replace: true });
    document.title = basename + ' | ' + toTitleCase(basepath);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Segment compact attached>
      <Header as="h2" icon textAlign="center">
        <Icon name="exclamation" circular />
        <Header.Content>Page Not Found</Header.Content>
      </Header>
    </Segment>
  );
};

export default PageNotFound;
