// From: https://react.semantic-ui.com/collections/menu/#variations-evenly-divided
//       https://www.pluralsight.com/guides/convert-a-json-file-to-an-array-in-react
import { Icon, Menu, SemanticICONS, SemanticWIDTHS } from 'semantic-ui-react';
import { ActiveItem } from './MainContainer';
import '../css/MainMenu.css';

interface NavigationItem {
  title: string;
  name: ActiveItem;
  icon: SemanticICONS;
}

const MainMenu = ({activeItem, setActiveItem}) => {
  const navigationItems: NavigationItem[] = [
    { title: 'About Me', name: 'aboutMe', icon: 'user' },
    { title: 'What I Like', name: 'technologies', icon: 'thumbs up' },
    { title: 'Projects', name: 'projects', icon: 'boxes' },
    { title: 'Contact', name: 'contact', icon: 'comments outline' },
  ];

  const onClick = (item: NavigationItem) => {
    setActiveItem(item.name);
  };

  return (
    <Menu inverted compact widths={navigationItems.length as SemanticWIDTHS} icon="labeled" attached="top">
      {navigationItems.map((item, index) => (
        <Menu.Item
          key={index}
          name={item.name}
          active={activeItem === item.name}
          onClick={() => onClick(item)}
        >
          <Icon name={item.icon} />
          {item.title}
        </Menu.Item>
      ))}
    </Menu>
  );
};

export default MainMenu;
