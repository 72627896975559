import { BrowserRouter } from 'react-router-dom';
import MainContainer from './tsx/MainContainer';
import './css/App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <BrowserRouter basename="/"> 
          <MainContainer />
        </BrowserRouter> 
      </header>
    </div>
  );
}

export default App;
