// From: https://react.semantic-ui.com/views/item/#variations-link
//       https://react.semantic-ui.com/elements/button/#usage-attached-events
//       https://react.semantic-ui.com/elements/loader/#types-text
//       https://react.semantic-ui.com/collections/table/#types-definition
//       https://react-chartjs-2.js.org/examples/line-chart
//       https://stackoverflow.com/questions/67727603/error-category-is-not-a-registered-scale
//       https://devsheet.com/code-snippet/show-vertical-line-on-data-point-hover-chartjs/
import { useState, useEffect, useRef, Fragment } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Segment, Tab, Item, List, Button, Image, Header, Dimmer, Loader, Grid, Table, Label, Flag } from 'semantic-ui-react';
import { Line } from 'react-chartjs-2';
import { LinearScale, TooltipItem, ChartTypeRegistry } from 'chart.js';
import { capitalize, uncapitalize, removeSpaces, removeSpacesURL } from '../ts/helper/StringUtils';
import { a, b, u, rootURL } from './helper/TextUtils';
import ImageGallery from 'react-image-gallery';
import moment from 'moment';
import Chart from 'chart.js/auto';
import imageMe from '../images/aboutMe/me.jpg';
import imageResume from '../images/aboutMe/resume-simple.jpg'; // generated by https://pdftoimage.com/pdf-to-jpg from resume.pdf, file size reduced with Photoshop (JPG Quality: 3)
import pdfResume from '../images/aboutMe/resume-simple.pdf';
import imageResumeGerman from '../images/aboutMe/resume-german-simple.jpg';
import pdfResumeGerman from '../images/aboutMe/resume-german-simple.pdf';
import technologies from '../ts/TechnologiesTeam';
import languages from '../ts/Languages';
import '../css/AboutMe.css';

interface AboutMeSection {
  name: string;
}

interface FAQSection {
  name: string;
  faqEntries?: FAQEntry[];
}

interface FAQEntry {
  question: string | JSX.Element;
  answer: string | JSX.Element;
}

const panes: AboutMeSection[] = [
  { name: 'Biography' },
  { name: 'Resume' },
  { name: 'Statistics' },
  { name: 'FAQ' },
];

const faq: FAQSection[] = [
  { name: 'Job Application', faqEntries: [
    {
      question: 'Where do you see yourself in 5 years?',
      answer:
        <div>
          Professionally, I want to become an independent {b('freelancer')} and {b('Full-Stack Developer')}.
          But first I want to work for a company to practice what I have learned in university:
          Agile methods like Scrum, CI/CD, Cloud Computing, Scalable Infrastructures, Marketing, Design and Project Management, etc.

          My goal for the next few years in web development is to learn more about frontend and backend frameworks.
          First I want to learn the frontend frameworks {a(technologies.react.name, technologies.react.link)}/{a('Next', 'https://nextjs.org/')}, {a('Vue', 'https://vuejs.org/')}/{a('Nuxt', 'https://nuxtjs.org/')}, and {a('Angular', 'https://angular.io/')}.
          And the backend frameworks {a('Express', 'https://expressjs.com/')}, {a('Laravel', 'https://laravel.com/')}, {a('Spring', 'https://spring.io/')}, and {a('Django', 'https://www.djangoproject.com/')}.

          In game development, I would like to publish and sell at least one game in the next 5 years.
          I also would like to work more on projects using {b('AI')}.
        </div>
    },
    {
      question: 'Where do you see yourself in 10 years?',
      answer:
        <div>
          Future goals I have after I know how to manage a company and marketing is to found my {b('own company')}.
          By that time, I also hope to reach a lot of people with the things I am doing on {b('YouTube')}, with {b('blog entries')}, or in communication channels like {b('Discord')}.
        </div>
    },
  ]},
  { name: 'Game Dev', faqEntries: [
    {
      question: 'What are your most favorite projects?',
      answer:
        <div>
          The projects I am managing that I see the most potential in are {a('Cave Combat', rootURL + '/projects/games/CaveCombat')}, {a('The Shrinking', rootURL + '/projects/games/TheShrinking')}, {a('Invaderion', rootURL + '/projects/games/Invaderion')}, {a('Hiramon', rootURL + '/projects/games/Hiramon')}, {a('Perfect Pitch Training', rootURL + '/projects/websites/PerfectPitchTraining')}, and the {a('Piano Fingering App', rootURL + '/projects/software/PianoFingeringApp')}.
          However, I also have more projects I didn't release to the public yet that I am proud about.
        </div>
    },
    {
      question: 'What are your most favorite games?',
      answer:
        <div>
          My favorite games are {b('Age of Empires')}, {b('The Legend of Zelda')}, {b('Terraria')}, and {b('Hearthstone')}.
          Other games I like are {u('Pokémon')}, {u('Gothic I/II')}, {u('Skyrim')}, {u('Hollow Knight')}, {u('Undertale')}, {u('Touhou')}, {u('Cave Story')}, {u('Stardew Valley')}, {u('Don\'t Starve Together')}, and {u('Rocket League')}.
        </div>
    },
  ]},
  { name: 'Other', faqEntries: [
    {
      question: 'Where are you from?',
      answer:
        <div>
          I am currently living in {b('Germany')}, but I was born in 1994 in {b('Santiago de Chile')}. My first language is still German though because it was taught to me first by my parents.
          Spanish is spoken in Chile. So I learned how to speak Spanish as a child to understand the other kids in our neighborhood.
          In 1999, we moved from Santiago to Concepción where I visited the "Deutsche Schule Concepción", a German school in Chile.
          Shortly after, in 2022, when I was 7 years old, we permanently moved to Germany. Since then, I live in Ulm where I went to school and made my university degree.
          I rarely speak Spanish here in Germany, but I still fully understand people that talk in Spanish.
        </div>
    },
    {
      question: 'Where would you like to move?',
      answer:
        <div>
          As for now, I can imagine staying in Ulm or moving to a different city in {b('Germany')} for my job.
          In the future, I would like to visit the {b('USA')} and {b('Japan')} for a while to improve my English and Japanese.
        </div>
    },
    {
      question: 'What do you do in your free time?',
      answer:
        <div>
          Besides {b('programming')}, {b('watching YouTube')}, and {b('gaming')} I am learning {b('music')} theory and how to play the piano. I also learn {b('Japanese')} and like to watch Anime. My childhood was full of things that come from Japan.
          I also drew a lot in the past. {b('Art')} was my best subject at school, but currently, I draw less often. I enjoy playing around with the latest {b('AI')} models, too.
          All the mentioned interests reflect in the projects I am doing (see {a('Piano Fingering App', rootURL + '/projects/software/PianoFingeringApp')}, {a('Perfect Pitch Training', rootURL + '/projects/websites/PerfectPitchTraining')}, {a('Invaderion', rootURL + '/projects/games/Invaderion')}, {a('Hiramon', rootURL + '/projects/games/Hiramon')}).
        </div>
    },
    {
      question: 'Can I learn something from you?',
      answer:
        <div>
          Yes, in the future I want to share my knowledge about programming, art, and music on {b('YouTube')} and/or in a {b('blog')}.
          I also plan to create a {b('Discord')} channel to talk about these things and discuss problems. Stay tuned!
        </div>
    },
  ]},
];

Chart.register(LinearScale);

let firstDate, maxX: number;
let minY: number, maxY: number;

const options: any = {
  tension: 0.4,
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    intersect: false,
    mode: 'index'
  },
  animation: {
    duration: 0
  },
  scales: {
    x: {
      type: 'linear',
      grid: {
        display: false
      },
      max: maxX,
      ticks: {
        maxRotation: 0,
        minRotation: 0,
        callback: function(value, index, values) {
          if (index === values.length - 1) return moment().format('YYYY-M-D');
          return moment(value).add(firstDate).format('YYYY-M-D');
        }
      }
    },
    y: {
      min: minY,
      max: maxY
    }
  },
  plugins: {
    legend: {
        display: false
    },
    tooltip: {
      enabled: true,
      callbacks: {
        title: function(tooltipItems: TooltipItem<keyof ChartTypeRegistry>[]) {
          return moment((tooltipItems[0].raw as {x: number}).x).add(firstDate).format('YYYY-M-D');
        }
      }
    }
  }
};

const plugins: any = [{
  afterDraw: chart => {
    if (chart.tooltip?._active?.length) {
        let x = chart.tooltip._active[0].element.x;
        let yAxis = chart.scales.y;
        let ctx = chart.ctx;
        ctx.save();
        ctx.beginPath();
        ctx.moveTo(x, yAxis.top);
        ctx.lineTo(x, yAxis.bottom);
        ctx.lineWidth = 1;
        ctx.strokeStyle = '#666666';
        ctx.setLineDash([3, 3]);
        ctx.stroke();
        ctx.restore();
    }
  }
}];

const AboutMe = ({basepath, basename}: {basepath: string, basename: string}) => {
  const tabRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [gitHubData, setGitHubData] = useState(null);
  const [lichessData, setLichessData] = useState(null);
  const [lichessRatingData, setLichessRatingData] = useState(null);
  const [language, setLanguage] = useState(languages.english);
  const lichessHref = 'https://lichess.org/@/Dietmar_Puschmann';
  const gitHubHref = 'https://github.com/depuschm';

  const onTabChange = (e, { panes, activeIndex }) => {
    if (activeIndex === -1) activeIndex = 0;
    const menuItem = panes[activeIndex].menuItem;
    const path = menuItem === 'FAQ' ? menuItem : uncapitalize(menuItem);
    navigate(removeSpaces('/' + basepath + '/' + path), { replace: true });
    document.title = capitalize(path) + ' | ' + basename;
    tabRef.current.state.activeIndex = activeIndex;
  };

  const navigation = (redirect: boolean) => {
    let index = tabRef.current.state.activeIndex;
    if (redirect) {
      const fullPathname = location.pathname;
      const pane = fullPathname.split('/')[2];
      if (pane) {
        index = panes.findIndex(({ name }) => removeSpaces(name.toLowerCase()) === removeSpacesURL(pane.toLowerCase()));
      }
    }
    onTabChange(null, { panes: tabRef.current.props.panes, activeIndex: index } );
  }

  useEffect(() => {
    navigation(true);

    fetch('https://api.github.com/users/depuschm')
    .then(req => req.json())
    .then(res => {
      if (!res.public_repos) return;
      const formattedData = [
        { name: 'Public Repos', value: res.public_repos },
        { name: 'Total Repos', value: res.public_repos + 13 }, // TODO: Fetch in backend with key
        { name: 'Followers', value: res.followers },
        { name: 'Following', value: res.following, }
      ];
      setGitHubData(formattedData);
    });

    fetch('https://lichess.org/api/user/dietmar_puschmann')
    .then(req => req.json())
    .then(res => {
      if (!res.perfs) return;
      const perfs = res.perfs;
      const count = res.count;
      let formattedData = [];
      if (res.online) formattedData.push({ name: 'Online', value: 'Yes', highlight: true });
      else formattedData.push({ name: 'Online', value: 'No' });
      formattedData.push(
        { name: 'Days Played', value: (res.playTime.total/60/60/24).toFixed(2) },
        { name: 'Blitz Games', value: perfs.blitz.games },
        { name: 'Rated Games', value: count.rated }
      );
      setLichessData(formattedData);
    });

    fetch('https://lichess.org/api/user/dietmar_puschmann/rating-history')
    .then(req => req.json())
    .then(res => {
      const blitzPoints = res.find(x => x.name === 'Blitz').points;
      firstDate = moment(blitzPoints[0][0] + '-' + (blitzPoints[0][1] + 1) + '-' + blitzPoints[0][2]);
      let minElo = Math.min();
      let maxElo = Math.max();
      const formattedBlitzPoints = blitzPoints.map(o => {
        const elo = o[3];
        if (elo < minElo) minElo = elo;
        if (elo > maxElo) maxElo = elo;
        return {
          x: moment(o[0] + '-' + (o[1] + 1) + '-' + o[2]).subtract(firstDate),
          y: elo
        };
      });
      const lastBlitzPoints = formattedBlitzPoints[formattedBlitzPoints.length - 1];
      const currentBlitzPoints = {
        x: moment().subtract(firstDate),
        y: lastBlitzPoints.y
      }
      if (lastBlitzPoints.x.format('YYYY-M-D') === currentBlitzPoints.x.format('YYYY-M-D')) {
        formattedBlitzPoints.pop();
      }
      formattedBlitzPoints.push(currentBlitzPoints);
      const formattedData = {
        datasets: [
          {
            label: 'Blitz',
            data: formattedBlitzPoints,
            fill: true,
            backgroundColor: 'rgba(75,192,192,0.2)',
            borderColor: 'rgba(75,192,192,1)',
          }
        ]
      };

      const paddingValueY = 20;
      const roundValueY = 10;
      maxX = currentBlitzPoints.x.diff(formattedBlitzPoints[0].x) + 50000000;
      minY = Math.round(minElo / roundValueY) * roundValueY - paddingValueY;
      maxY = Math.round(maxElo / roundValueY) * roundValueY + paddingValueY;
      options.scales.x.max = maxX;
      options.scales.y.min = minY;
      options.scales.y.max = maxY;

      setLichessRatingData(formattedData);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateLabel = (nextIndex: number) => {
    if (nextIndex === 0) setLanguage(languages.english);
    else setLanguage(languages.german);
  };

  return (
    <Segment compact attached>
      <Tab ref={tabRef} onTabChange={onTabChange} panes={[
        { menuItem: panes[0].name, render: () =>
          <Tab.Pane className='biography'>
            <Item.Group>
              <Item>
                <Item.Image src={imageMe} />
      
                <Item.Content>
                  <Item.Description>
                    <Segment>
                      <List bulleted>
                        <List.Item>I have a Bachelor of Science in Media Informatics from the University of Ulm and am open to work opportunities.</List.Item>
                        <List.Item>I live in Ulm, Germany, and make games, websites and software.</List.Item>
                      </List>
                    </Segment>
                    <p>
                      Hello, my name is Dietmar Puschmann. I have a Bachelor of Science in Media Informatics from the University of Ulm, Germany.
                      I am living in Ulm and am doing a Master's degree in the same direction and expect to finish my studies during the spring or summer of 2023.
                      Besides studying, I have a small job at the same university until March 2023. We are working on a VR software with the Unity Game Engine and C#.
                      I am looking for a job starting in April since my work contract ends in March. Send me a message on LinkedIn/Xing or use the contact form on my website
                      if you are interested in working together. You can read more about me, the technologies I use, and the projects I was involved in on my website.
                    </p>
                  </Item.Description>
                </Item.Content>
              </Item>
            </Item.Group>
          </Tab.Pane>
        },
        { menuItem: panes[1].name, render: () =>
          <Tab.Pane className='resume'>
            <Segment attached="top">
              <Label attached='top left' className='resume-label'>
                <Flag name={language.icon}></Flag>
                {language.name}
              </Label>
              <ImageGallery
                items={[
                  { original: imageResume, originalAlt: 'Resume' },
                  { original: imageResumeGerman, originalAlt: 'Lebenslauf' },
                ]}
                showBullets={true}
                showPlayButton={false}
                additionalClass='image-gallery-resume'
                onBeforeSlide={(nextIndex) => updateLabel(nextIndex)}
              />
            </Segment>
            <Button.Group attached="bottom" widths='2'>
              <Button as="a" href={pdfResume} download="Resume Dietmar Puschmann" content="Download PDF (English)"/>
              <Button.Or />
              <Button as="a" href={pdfResumeGerman} download="Lebenslauf Dietmar Puschmann"content="Download PDF (German)"/>
            </Button.Group>
          </Tab.Pane>
        },
        { menuItem: panes[2].name, render: () =>
          <Tab.Pane>
            <Header size="medium" textAlign="center">GitHub Statistics (<a href={gitHubHref} target="_blank" rel="noreferrer">@depuschm</a>)</Header>
            { !gitHubData &&
              <Segment className="gitHub">
                <Dimmer active inverted>
                  <Loader>Loading</Loader>
                </Dimmer>
              </Segment>
            }
            { gitHubData &&
              <Grid columns={2} centered verticalAlign="middle">
                <Grid.Column mobile={16} tablet={16} computer={3}>
                  <Table definition>
                    <Table.Body>
                      {gitHubData.map((item, index) => (
                        <Table.Row key={index}>
                          <Table.Cell>{item.name}</Table.Cell>
                          <Table.Cell>{item.value}</Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={13}>
                  <Image src="https://ghchart.rshah.org/depuschm" size="huge" alt="GitHub chart" />
                </Grid.Column>
              </Grid>
            }
            <br /><hr />
            <Header size="medium" textAlign="center">"5 Min Blitz" Elo Progression (<a href={lichessHref} target="_blank" rel="noreferrer">Lichess</a>)</Header>
            { (!lichessData || !lichessRatingData) &&
              <Segment className="lichess">
                <Dimmer active inverted>
                  <Loader>Loading</Loader>
                </Dimmer>
              </Segment>
            }
            { lichessData && lichessRatingData &&
              <Grid columns={2} centered verticalAlign="middle">
                <Grid.Column mobile={16} tablet={16} computer={3}>
                  <Table definition>
                    <Table.Body>
                      {lichessData.map((item, index) => (
                        <Table.Row key={index}>
                          <Table.Cell>{item.name}</Table.Cell>
                          {!item.highlight && <Table.Cell>{item.value}</Table.Cell>}
                          {item.highlight && <Table.Cell className="highlight">{item.value}</Table.Cell>}
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={13}>
                  <Line className='lichess-graph' options={options} plugins={plugins} data={lichessRatingData} />
                </Grid.Column>
              </Grid>
            }
          </Tab.Pane>
        },
        { menuItem: panes[3].name, render: () =>
          <Tab.Pane>
            {faq.map((faqSection, index) => (
              <Fragment key={index}>
                <Header size="large">{faqSection.name}</Header>
                <Table definition>
                  <Table.Body>
                    {faqSection.faqEntries.map((faqEntry, index) => (
                      <Table.Row key={index}>
                        <Table.Cell className='middleCell'>{faqEntry.question}</Table.Cell>
                        <Table.Cell>{faqEntry.answer}</Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </Fragment>
            ))}
          </Tab.Pane>
        },
      ]} />
    </Segment>
  );
};

export default AboutMe;
