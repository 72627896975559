// From: https://react.semantic-ui.com/views/item/#variations-link
//       https://stackoverflow.com/questions/45740484/map-and-reduce-array-of-objects-with-a-children-array-down-to-array-of-children#45740600
//       https://stackoverflow.com/questions/34521797/how-to-add-multiple-classes-to-a-reactjs-component#34521945
//       https://stackoverflow.com/questions/48830316/how-do-you-reload-an-iframe-with-reactjs
import { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Segment, Tab, Header, Label, Flag, Table, Icon, Image, FlagNameValues } from 'semantic-ui-react';
import { SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';
import { capitalize, uncapitalize, removeSpaces, removeSpacesURL } from '../ts/helper/StringUtils';
import { br, a, b, u, rootURL } from './helper/TextUtils';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import classNames from 'classnames';
import technologies from '../ts/TechnologiesTeam';
import devices from '../ts/Devices';
import languages from '../ts/Languages';
import platforms from '../ts/Platforms';
import browsers from '../ts/Browsers';
import '../css/Projects.css';

// Games
import image1DungeonCrawler from '../images/projects/dungeonCrawler/image1.jpg';
import image2DungeonCrawler from '../images/projects/dungeonCrawler/image2.jpg';
import image3DungeonCrawler from '../images/projects/dungeonCrawler/image3.jpg';
import image4DungeonCrawler from '../images/projects/dungeonCrawler/image4.png';

// Websites
import image1Portfolio from '../images/projects/portfolio/image1.png';
import image2Portfolio from '../images/projects/portfolio/image2.png';
import image3Portfolio from '../images/projects/portfolio/image3.png';
import image4Portfolio from '../images/projects/portfolio/image4.png';

import image1PerfectPitchTraining from '../images/projects/perfectPitchTraining/image1.png';
import image2PerfectPitchTraining from '../images/projects/perfectPitchTraining/image2.png';
import image3PerfectPitchTraining from '../images/projects/perfectPitchTraining/image3.png';

// Software
import image1PianoFingeringApp from '../images/projects/pianoFingeringApp/image1.jpg';
import image2PianoFingeringApp from '../images/projects/pianoFingeringApp/image2.jpg';
import image3PianoFingeringApp from '../images/projects/pianoFingeringApp/image3.png';

// Art
import image1LandscapePhotography from '../images/projects/landscapePhotography/image1.jpg';
import image2LandscapePhotography from '../images/projects/landscapePhotography/image2.jpg';
import image3LandscapePhotography from '../images/projects/landscapePhotography/image3.jpg';
import image4LandscapePhotography from '../images/projects/landscapePhotography/image4.png';
import image5LandscapePhotography from '../images/projects/landscapePhotography/image5.png';
import image6LandscapePhotography from '../images/projects/landscapePhotography/image6.png';

import image1Fanart from '../images/projects/fanart/image1.jpg';
import image2Fanart from '../images/projects/fanart/image2.jpg';
import image3Fanart from '../images/projects/fanart/image3.png';
import image4Fanart from '../images/projects/fanart/image4.jpg';
import image5Fanart from '../images/projects/fanart/image5.png';
import image6Fanart from '../images/projects/fanart/image6.jpg';
import image7Fanart from '../images/projects/fanart/image7.png';

interface YearRange {
  start: number;
  end: number;
}

interface ProjectSection {
  name: string;
  defaultActiveIndex?: number,
  panes?: Project[];
}

export interface Project {
  name: string;
  year?: number | number[] | YearRange;
  comment?: string;
  //free?: boolean
  upcoming?: boolean;
  //active?: boolean
  youtube?: string;
  video?: string;
  image?: string;
  gallery?: Gallery;
  hidden?: boolean;
  entries?: ProjectEntries;
}

interface ProjectEntries {
  team?: JSX.Element;
  links?: JSX.Element;
  references?: JSX.Element;
  attachments?: JSX.Element;
  technologies?: JSX.Element;
  utensils?: JSX.Element;
  platforms?: JSX.Element;
  browsers?: JSX.Element;
  devices?: JSX.Element;
  languages?: JSX.Element;
  tags?: JSX.Element;
  //similarGames?: JSX.Element;
  summary?: string | JSX.Element;
  credits?: string | JSX.Element;
  description?: string | JSX.Element;
  //contribution?: string | JSX.Element;
}

interface ProjectLabel {
  name: string;
  href?: string;
  image?: string;
  icon?: SemanticICONS | '';
  detail?: string;
}

interface ProjectFlag {
  name: string;
  icon?: FlagNameValues;
}

interface Gallery {
  showBullets?: boolean;
  thumbnailPosition?: 'top' | 'right' | 'bottom' | 'left' | undefined;
  items: ReactImageGalleryItem[];
}

type LabelType = '' | 'basic';

const getLabels = (basic: LabelType, ...labels: ProjectLabel[]): JSX.Element => <>{
  labels.map((label, index) =>
    <Label
      key={index}
      as={label.href && 'a'}
      className={classNames({
        'noBorder': basic && !label.href,
        'imageLabel': label.image,
        'iconLabel': label.icon
      })}
      href={label.href}
      target="_blank"
      rel="noopener"
      image={label.image && true}
      basic={basic === 'basic' && true}>
      {label.image && <img src={label.image} alt="img" />}
      {label.icon && <Icon name={label.icon} size='large' />}
      {label.name}
      {/*label.detail && <> <Label>{label.detail}</Label></>*/}
    </Label>
  )
}</>;

const getTags = (basic: LabelType, ...tags: string[]): JSX.Element => {
  let labels: ProjectLabel[] = [];
  tags.map((tag) => labels.push({ name: tag }));
  return getLabels(basic, ...labels);
};

const getFlags = (basic: LabelType, ...flags: ProjectFlag[]): JSX.Element => <>{
  flags.map((flag, index) =>
    <Label
      key={index}
      className={classNames({
        'noBorder': basic,
        'iconLabel': flag.icon
      })}>
      <Flag name={flag.icon}></Flag>
      {flag.name}
    </Label>
  )
}</>;

const originalHeightGallery = 370;

/**
 * Categories:
 * - Games
 * - Websites
 * - Software
 * - Art/Models
 * - Music/Sounds
 * - Videos/Playlists
 * - Assets?
 * - Gists?
 */
const panes: ProjectSection[] = [
  { name: 'Games', defaultActiveIndex: 0, panes: [
    {
      name: 'Dungeon of Dangers',
      year: 2022,
      comment: 'Ludum Dare 50',
      upcoming: true,
      youtube: 'UPTVsOsO16Y',
      entries:
      {
        team: getLabels('basic',
          { name: 'Dietmar Puschmann', detail: 'Programmer' },
          { name: 'Steve Messier', detail: 'Character Artist' },
          { name: 'Matthias Young', detail: '3D Artist, Level Designer', href: 'https://www.ferociousindustries.com' },
        ),
        links: getLabels('basic',
          { name: 'Ludum Dare 50', href: 'https://ldjam.com/events/ludum-dare/50/dungeon-of-dangers' },
          { name: 'itch.io', href: 'https://rayo.itch.io/dungeon-of-dangers' }
        ),
        technologies: getLabels('',
          technologies.unity,
          technologies.cSharp,
          technologies.blender,
          technologies.characterCreator,
        ),
        tags: getTags('', '3D', 'Action-Adventure', 'Third-Person', 'Multiplayer', 'Singleplayer'),
        platforms: getLabels('',
          platforms.windows,
        ),
        devices: getLabels('',
          devices.keyboard,
          devices.mouse,
          devices.gamepad,
        ),
        languages: getFlags('',
          languages.english,
        ),
        summary: 'Dungeon of Dangers is a game about escaping from a dangerous dungeon. It can be played alone or with friends.',
        description:
          <div>
            In 2022, I entered Ludum Dare with a team again. I was responsible for programming, Matthias for modeling and level design, and Steve for characters and playtesting.
            This time, we attempted to make a {b('Multiplayer')} game using {a('PUN 2', 'https://assetstore.unity.com/packages/tools/network/pun-2-free-119922')} which offers free hosting for up to 20 players at the same time.
            After the theme of the jam "{u('Delay the inevitable')}" was announced we discussed what game we want to make. Initially, we wanted to create a game with different escape rooms that have to be exited within a certain amount of time. To fit the theme, we wanted to add different ways to delay the timer.
            A big inspiration for this idea was {a('Mr.Beast\'s escape room video', 'https://www.youtube.com/watch?v=3jS_yEK8qVI')}. Especially the moving spikes inspired us
            The final game turned out to be more like an "Escape from a dungeon" game. In Ludum Dare 50, it was possible to enter the extra format rather than the jam or compo. In the extra format, people have 2 weeks to finish a game.
            Even though we had more time than usual we did not finish the game. However, we made a trailer with two scenes that can be downloaded and played as a demo.
            A character controller made by {a('Opsive', 'https://assetstore.unity.com/packages/tools/game-toolkits/ultimate-character-controller-99962')} from the Unity Asset Store was used to develop this game.{br}

            The idea in this dungeon is to introduce something new in every room. The first room doesn't have anything, it is just a room to wait for other players. When all players are ready they can start to explore the dungeon.
            First, in the {b('tutorial')} rooms, controls (crouching, jumping, and running) and basic interactions with pressure plates are introduced. Later the players get weapons and more difficult puzzles have to be solved.
            After the tutorial, the dangers of the dungeons are introduced. For each danger, one level (= a scene with multiple rooms) is planned for the game. In total, we wanted to have 3 dangers/levels for the demo, but we only finished one.
            The first danger we had in mind was {b('spikes')} moving towards you. Players have to run to escape from these spikes. In case a player dies, it doesn't mean the game is over immediately. When another player reaches a respawn point all players are resurrected. While dead, players can watch living players moving through the dungeon similar to Fortnite.
            The second danger we thought about is a {b('poisonous gas')}. In these levels, also first aid kits will be introduced to restore life taken by the gas.
            The last danger is {b('rising lava')}. The goal for the players is to jump on different platforms from a volcano that is going to erupt soon. When the players are out, they left the dungeon and the game is over.{br}
            
            The dungeon design was first designed in {a(technologies.blender.name, technologies.blender.link)} together with the team. After we designed the dungeon, Matthias started to make models for the rooms (Floors/Walls, Gates, Spikes, etc.), created prefabs and optimized them with LODs, and put those into a scene. He also added Post Processing.
            Meanwhile, Steve created some characters with {a(technologies.characterCreator.name, technologies.characterCreator.link)}, made prefabs for them, and set up item positions and references for those. Throughout the development of the game, Steve helped me to fix bugs and problems. Especially Multiplayer bugs were easier to find and fix with two people.
            For easier integration into the game, I made an Editor Window to integrate characters seamlessly into the game. To keep everything modular and to upgrade easily I didn't touch third-party code at all. For the main menu, this required a modified copy of the class, but luckily all third-party assets we used were pretty accessible due to variable properties, overridable functions, and a lot of events to listen from.{br}
            
            I like how this game turned out and would like to finish the ideas we have for this project in the future. It was a great experience to learn how to create a 3D Multiplayer game.
          </div>
      }
    },
    {
      name: 'Invaderion',
      year: 2022,
      comment: 'Messy Coder Game Jam #3',
      youtube: 'CITDygzIKvc',
      entries:
      {
        team: getLabels('basic',
          { name: 'Dietmar Puschmann', detail: 'Everything' },
        ),
        links: getLabels('basic',
          { name: 'itch.io', href: 'https://rayo.itch.io/invaderion' },
          { name: 'Messy Coder Game Jam', href: 'https://itch.io/jam/messycoder-invasion/rate/1409409'},
          { name: 'Stage Theme', href: 'https://musescore.com/user/43142831/scores/7582685/s/o7E5tE' },
        ),
        technologies: getLabels('',
          technologies.unity,
          technologies.cSharp,
          technologies.flStudio,
          technologies.museScore,
        ),
        tags: getTags('', '2.5D', 'Bullet-Hell', 'Shoot \'em up', 'Action', 'Hard', 'Music Game'),
        platforms: getLabels('',
          platforms.windows,
        ),
        devices: getLabels('',
          devices.keyboard,
          devices.gamepad,
        ),
        languages: getFlags('',
          languages.english,
        ),
        summary: 'Invaderion is a Bullet-Hell Shooter about invading a planet. There are 12 bosses to beat and 5 achievements that can be unlocked.',
        description:
          <div>
            I know music can play a very important role in a game. For this reason, I learned how to make {b('my own music')}.
            After I learned to play piano in 2021, I was learning how to make music on the computer with the DAW {a(technologies.flStudio.name, technologies.flStudio.link)} and/or the music notation program {a(technologies.museScore.name, technologies.museScore.link)}.
            What was missing was a game that uses my music, so I decided to enter a game jam again. It was not time for Ludum Dare so I joined the {a('Messy Coder', 'https://www.youtube.com/c/TheMessyCoder')} Game Jam. A smaller game jam, but also a game jam in which people might receive prices when submitting a good game.
            The theme of the jam was {u('Invasion')}, which was perfect for a {b('Bullet-Hell')} Shooter game. Bullet-Hell (sometimes called Danmaku) is a subgenre of the genre "Shoot 'em up" in which the player faces an overwhelming amount of enemy bullets or projectiles that he has to dodge.
            The hitbox of the player is usually only a few pixels in size in this type of game and often a single hit already leads to the loss of an attempt. Well-known representatives of this game genre are the {u('Touhou Project')} games, {u('DonPachi')}, and {u('Ikaruga')}. Newer representative of this genre are {u('Sky Force Reloaded')} and {u('Undertale')} (an RPG with Bullet-Hell gameplay).{br}
            
            Initially, the game was supposed to have a {b('level')} before encountering a boss appears where the player flies around and beats some weak enemies. So I started to make the music for the game first by composing a theme for the level (stage theme).
            With the first part of the music I want to create tension and with the later part I completely change the direction of the music into something that expresses freedom and self-confidence while through the sky - until the {b('boss')} appears where I wanted to change the music.
            Sadly, there was no time left to compose another music piece and the level before the boss so I only focused on the boss fight and used the stage theme for it.
            The game consists of beating the boss multiple times. The player invades a planet where the previous invader currently rules. In the first level, the boss has only 300 health and gets +100 health and new bullet patterns every time the player defeats him. The final boss has 1500 health.
            After every defeat, the player returns to the main menu where he can watch his achievements, the current level, and his ship in the main menu.
            The {b('bullet shots')} by the enemy are {b('in sync with the music')}. After every fourth note, the bullet patterns change (this can be improved in the future by changing the bullet pattern every measure instead).{br}

            In the future, the plan is that the player plays another invader at every level and fights against the previous invader that invaded the planet (That means that you will fight yourself).
            That's also the reason why I named the game Invaderion, Invaderion is a blend word from "Invader Invasion". I made a building animation for ships before entering the main menu mainly to slowly show the player his new ship. The animation turned out pretty satisfying and with more ships, it will be even better.
            Other things that are missing are firing and impact effects to make the game feel more alive. Item drops from enemies, ship upgrades, and different bullet patterns for the player are planned to increase replayability even more, too.
            I also want to add a {b('Multiplayer')} mode in the future like in Sky Force Reloaded, so I made the game screen wide for two players (Bullet-Hell games are usually not played in widescreen format).
            Regarding music, I hope to compose more themes for different levels, sync enemy positions with the music and change the background based on the playing time.
            The jam was very fun and I even {b('won a prize')} for being the game that kept judges addicted the most to keep playing. A great start for an interesting Bullet-Hell game!
          </div>
      }
    },
    {
      name: 'Unstable World',
      year: 2021,
      comment: 'Ludum Dare 49',
      youtube: 'DXKTZaG9fRk',
      hidden: true,
      entries:
      {
        team: getLabels('basic',
          { name: 'Dietmar Puschmann', detail: 'Programmer' },
          { name: 'Matthias Young', detail: '3D Artist', href: 'https://www.ferociousindustries.com' },
          { name: 'Steve Messier', detail: 'Character Artist' },
        ),
        links: getLabels('basic',
          { name: 'Ludum Dare 49', href: 'https://ldjam.com/events/ludum-dare/49/unstable-world-2' },
          { name: 'itch.io', href: 'https://rayo.itch.io/unstable-world' },
        ),
        technologies: getLabels('',
          technologies.unity,
          technologies.cSharp,
          technologies.blender,
          technologies.characterCreator,
          technologies.iClone,
        ),
        tags: getTags('', '3D', 'Shooter', 'Action', 'Wave Survival'),
        platforms: getLabels('',
          platforms.windows,
        ),
        devices: getLabels('',
          devices.keyboard,
          devices.mouse,
          devices.gamepad,
        ),
        languages: getFlags('',
          languages.english,
        ),
        summary: 'Unstable World is a Third-Person Wave Survival Game. Enter a chaotic arena full of action and natural phenomena. Fight your way to the final round.',
        description:
          <div>
            After successfully publishing {a('The Shrinking', rootURL + '/projects/games/TheShrinking')} for the last Ludum Dare game jam I entered Ludum Dare again. I entered the jam as a team again with Matthias Young who was responsible for 3D art and modeling.
            We also invited Steve Messier, our character artist to this game. Steve was using {a(technologies.characterCreator.name, technologies.characterCreator.link)} to create character models and {a(technologies.iClone.name, technologies.iClone.link)} to animate them.
            This time, the theme of the jam was {u('Unstable')}. We decided to create a game with an unstable world, in which the world rapidly changes its state by spawning and despawning objects.
            The character controller {a('Invector', 'https://assetstore.unity.com/packages/tools/game-toolkits/invector-third-person-controller-shooter-template-84583')} from the Unity Asset Store was used to develop this game.{br}

            The goal of the game is to survive multiple waves of enemies. Multiple weapons can be used to defend that are randomly spawned in chests or sold by a neutral vendor. Enemies get always stronger over time.
            The player gets {b('tasks')} in every wave until the {b('final boss')} appears that has to be fought. During the game massive trees, rocks and buildings spawn and despawn from nowhere. This is the unstable part of this game: The world is never in a constant state.
            To achieve this, I had to program a {b('spawner')} that detects valid spots to spawn. For this, I check if any objects are blocking the spawn point. I also check the slope of the terrain, if the slope is not too high an object can be spawned.
            Enemies are spawned by chance and only if not too many enemies are alive simultaneously. Every enemy has a chance to drop an item, such as ammo, money, or first aid kits to heal the player. A navigation mesh is also defined to limit the regions in which enemies can move.{br}
            
            Other than that, there is a minimap that can be resized to see nearby objects from a top-view, neutral hazards like sand tornados that can damage the player and enemies, and rising water walls to limit the world space.
            In the beginning, there is also an introduction scene in which a character speaks to the player. The animation of the character in the introduction scene was done with iClone and is lip-synced with the spoken text. We used {b('text-to-speech')} (TTS) to convert normal language text into an audio file that is played while the character animation is playing.{br}
            
            The game turned out very crazy and alive by spawning so many things in the world. In the future, I hope to change the enemy design and world a little.
          </div>
      }
    },
    {
      name: 'The Shrinking',
      year: 2021,
      comment: 'Ludum Dare 48',
      youtube: 'fmU_XoBpSog',
      entries:
      {
        team: getLabels('basic',
          { name: 'Dietmar Puschmann', detail: 'Programmer' },
          { name: 'Matthias Young', detail: '3D Artist, Level Designer', href: 'https://www.ferociousindustries.com' },
        ),
        links: getLabels('basic',
          { name: 'Ludum Dare 48', href: 'https://ldjam.com/events/ludum-dare/48/the-shrinking' },
          { name: 'itch.io', href: 'https://rayo.itch.io/the-shrinking' },
        ),
        technologies: getLabels('',
          technologies.unity,
          technologies.cSharp,
          technologies.blender,
        ),
        tags: getTags('', '2.5D', 'Platformer', 'Side-Scroller', 'Puzzle', 'Athmospheric'),
        platforms: getLabels('',
          platforms.windows,
        ),
        devices: getLabels('',
          devices.keyboard,
        ),
        languages: getFlags('',
          languages.english,
        ),
        summary: 'The Shrinking is a short, but immersive Side-Scrolling Adventure. Flee from the liquid that makes you shrink and embark on a journey that takes you deeper and deeper into the earth.',
        description:
          <div>
            While doing my Master's degree I also started to work on some personal projects. Most personal projects were Unity projects to get better in Unity because I also was working with Unity for my university job.
            The last time I entered a game jam was in 2014 with the game {a('Primitives', rootURL + '/projects/games/Primitives')}, so I decided that after so long it was time to enter a game jam again. This time, unlike all other times I entered a game jam, I wanted to make a game in a {b('team')}.
            I invited Matthias Young, a 3D graphics artist, and Blender modeler to enter Ludum Dare 48 with me. Ludum Dare is the biggest game jam on the internet that is held semi-annually. Back in 2021, there were two events to enter: the compo and the jam.
            The {b('jam')} is a more relaxed version of the compo where developers can make a game in a team, have more time to submit their game, and can use third-party assets. After the games are made, the games are submitted and rated.
            If assets in the game are not custom-made, the game has to be voted out of the category the assets belong to for a fair rating. In our case, we had to opt out of "graphics" because we used some third-party models from {a('NatureManufacture', 'https://assetstore.unity.com/publishers/6887')} and the player character from {a('Mixamo', 'https://www.mixamo.com/#/?page=1&type=Character')}.
            The theme of the jam was {u('Deeper and Deeper')}. This theme is seen in multiple ways in our game. On the one hand, the journey takes the player deeper and deeper into the earth and on the other hand, the camera zooms deeper and deeper whenever the player is shrinking.
            Jules Verne's classic novel {a('Journey to the Center of the Earth', 'https://en.wikipedia.org/wiki/Journey_to_the_Center_of_the_Earth')} was an inspiration for us to let the player go deeper and deeper into the earth. Many ideas for this game can be can be taken from this novel.{br}

            The game is about a {b('strange liquid')} that falls to the earth. This liquid may look like ordinary rain, but when if it is touched, it has the effect of {b('making people smaller')}.
            During the game, the player has to walk through levels and avoid touching this liquid for too long. Sometimes it is unavoidable to become smaller to reach certain places, but becoming too small is always fatal because the player then drowns in puddles. Especially in levels where it is raining the player has to look for safe places to avoid being shrunk immediately.
            From level to level, the player gets {b('always smaller')}. The levels are designed to exactly show that: When the player has a normal size he crosses bridges, and after being smaller he enters birdhouses. When the player is smaller than plants, he has to jump from leaf to leaf and even ride or fight animals that are now as big as him.
            Later in the game, the player is so small that the journey takes place below the earth where worms and other animals are a thread. Even here, the player is not safe from the liquid, because it slowly seeps through the earth.{br}
          
            Not all of these ideas have been implemented in the demo yet, but the demo shows that this idea is feasible. This is one of my projects that might have high potential when working on it further. I don't know any games about shrinking with this setting.
            Future plans for the game are to improve the game controls (also being able to hold at an edge to make climbing easier), add NPCs into the world (animals, other people that are shrunk), and work further on the story and levels of the game.
            The first demo made for the game jam was liked a lot by the people in the jam and the {b('trailer')} was also {b('seen a lot of times on YouTube')}. This is a good sign that we made most things well in the game.
          </div>
      }
    },
    {
      name: 'Dungeon Crawler',
      year: 2018,
      comment: 'Bachelor Thesis',
      gallery: {
        items: [
          { original: image1DungeonCrawler },
          { original: image2DungeonCrawler },
          { original: image3DungeonCrawler },
          { original: image4DungeonCrawler },
        ],
      },
      entries:
      {
        team: getLabels('basic',
          { name: 'Dietmar Puschmann', detail: 'Everything' },
        ),
        links: getLabels('basic',
          { name: 'Paper (DOI)', href: 'https://dl.acm.org/doi/abs/10.1145/3341215.3356288' },
          { name: 'Paper (Read Online)', href: 'https://drive.google.com/file/d/1-Z9-ttiQQAc9wkgPYrYjLkyJF-9rjSzO/view' },
          { name: 'Procedural Dungeon Generator (Blog Entry)', href: 'https://journal.stuffwithstuff.com/2014/12/21/rooms-and-mazes/' },
        ),
        attachments: getLabels('basic',
          { name: 'Manual (Game)', href: 'https://drive.google.com/file/d/1VOvNK0GhWwRLwEiD6nQLePb1WS_DNBGr/view' },
          { name: 'Manual (Parameters)', href: 'https://drive.google.com/file/d/1R2BfjlSHo1-nOQbMNhWFxlhjC005lZtM/view' },
        ),
        technologies: getLabels('',
          technologies.unity,
          technologies.cSharp,
        ),
        tags: getTags('', '3D', 'Procedural Content Generation', 'Dungeon Crawler', 'Maze', 'Rogue-lite', 'Turn-based'),
        platforms: getLabels('',
          platforms.windows,
        ),
        devices: getLabels('',
          devices.keyboard,
        ),
        languages: getFlags('',
          languages.english,
        ),
        summary: 'Dungeon Crawler is a simple Dungeon Crawler game. The goal is to find all targets in a procedurally generated maze. There are friendly NPCs and enemies that will try to stop the player from reaching the targets. The maze generation is very customizable.',
        description:
          <div>
          I really like to play games with procedural content generation and I already created my own games that generate procedural worlds ({a('Cave Combat', rootURL + '/projects/games/CaveCombat')}, {a('Primitives', rootURL + '/projects/games/Primitives')}). For this reason, I chose to create a game with a procedurally generated world for my Bachelor Thesis as well.
          This time, I chose to make a classic {b('rogue-lite')} game with a maze, player, traps, enemies, and targets to find. The terms "Rogue-lite" and "Rogue-like" are used for games that have similarities with the game "Rogue" whereas "Rogue-like" is more strict than "Rogue-lite".
          Rogue is a traditional game from 1980 characterized by a dungeon crawl through procedurally generated levels, turn-based gameplay, grid-based movement, and permanent death of the player character. Nowadays, it is a common subgenre of computer games.
          Rogue is a game with a 2D maze but I wanted to make a game with a 3D maze similar to {u('Scarab of Ra')}, a game from 1988 for Mac OS by Rick Holzgrafe that I played a lot in my childhood. Scarab of Ra is a 2D game as well but generates images of a 3D maze. I always wanted to create a game like Scarab of Ra and combining this goal with my Bachelor Thesis was very fitting.{br}

          There is a popular {a('blog entry by Bob Nystrom', 'https://journal.stuffwithstuff.com/2014/12/21/rooms-and-mazes/')}, the author of the book {a('Game Programming Patterns', 'http://gameprogrammingpatterns.com/')}, that covered how to make a procedural dungeon generator.
          It is a very interesting article with a lot of interactable examples that I highly recommend. The source code is provided in the article as well and is written in {a('Dart', 'https://dart.dev/')}. For my game, I translated the source code into C# and modified it in the way I liked.
          Important concepts that are needed to generate a maze in Nystrom's article are the {b('creation of a perfect maze')} (by using the growing tree algorithm for example), the {b('flood fill algorithm')} for finding positions to generate a maze, {b('finding a spanning tree')} for connecting rooms, and the {b('removal of dead ends')}.
          In addition, I added shallow water with a modified version of the flood fill algorithm, and modified rooms to have a non-rectangular shape by overlapping multiple rectangles together.{br}

          All these concepts rely on parameters that can be set up by the player before the game begins. In my Bachelor thesis, I compared {b('3 versions')} of the game to see which is liked most. The versions {b('differed in the control')} they gave to the player to generate the dungeon ({b('none, limited, high')}).
          Depending on the version the players played the parameters didn't have to be set up and used default values (none), could be set up by choosing one of three options (limited), or could be set up by choosing values in a given range (high). In a {b('study')} with 12 participants, the results showed that choosing values in a given range, so high control to generate a maze, is the most popular.
          The player has to set up {b('22 parameters')} before the dungeon is generated. A preview that can be recreated shows an example-generated maze so that the player fairly knows what the dungeon will look like at the end.
          At this point, I have to say that my main inspiration for this parameter setup was like the parameter setup from {u('Don\'t Starve Together')} which offered great control to create a procedural world. However, only limited control by choosing from a fixed amount of options rather than a range of values.
          Don't Starve Together also doesn't have a {b('preview')} as my game does. The preview is simply the map of the game. After the parameter setup, the map is shown on the top left of the screen and is revealed whenever the player walks to a new area in the maze.
          The revelation of the map was done similarly I coded light in {a('Cave Combat', rootURL + '/projects/games/CaveCombat')}. The idea is the same as the torches update light in {u('Terraria')}: The visibility of the tile depends on the distance to the player and the tiles in between. For that, every tile has a lighting value, a multiplicator that is in the range between 0 and 1.
          For example, walls have a lighting value of 0 and floors a value of 0.2. If we begin from the tile the player currently is with a starting value of 1 we can decrease this value by multiplying it with the lighting values on the way to the new tile until a certain threshold to stop was exceeded.
          To find the tiles between the player and another tile I used {b('Bresenham\'s algorithm for line rasterization')} that I recently learned in a Computer Graphics course. I used it like a 2D raytracer and it worked very well.
          In the end, I decided to only use visible or not visible tiles for the map. There are no values in between to simplify the revelation, but the math behind is almost the same as in Cave Combat and Terraria.{br}
          
          By changing the parameters the player also changes the difficulty of the game. Especially the number of NPCs like enemies and fairies is a big factor that can rapidly increase or decrease difficulty.
          Since this is a turn-based game NPCs move whenever the player moves. While moving, the player updates a {b('distance field')} around him. This is a very efficient way to check the distance between enemies and the player.
          Nearby enemies will start following the player until he either finds shallow water that enemies can't enter (essentially a safe place in the dungeon), teleports away by using a teleporter, or runs into them. If the player and an enemy are in the same tile the player will lose a certain amount of life and the enemy will despawn.
          The damage dealt by an enemy is a parameter that can be changed before generating the dungeon as well.
          In addition to enemies, there are some other NPCs like fairies that heal the player and can fly over shallow water, and arrows pointing to the closest target. Targets are collectibles. If all targets were collected (the order doesn't matter) the player wins.
          </div>
      }
    },
    {
      name: 'Hiramon',
      year: { start: 2015, end: 2016 },
      comment: 'University Project',
      youtube: 'SXU1ALy7HSo',
      entries:
      {
        team: getLabels('basic',
          { name: 'Dietmar Puschmann', detail: 'Programmer, Designer' },
          { name: '+4 others', detail: 'Programmer, 3D-Artist, Psychologist' },
        ),
        links: getLabels('basic',
          { name: 'Website', href: 'https://www.uni-ulm.de/in/serious-games/lehre/anwendungsmodulseminar/' },
        ),
        technologies: getLabels('',
          technologies.unity,
          technologies.cSharp,
          technologies.blender,
          technologies.photoshop,
          technologies.illustrator,
        ),
        tags: getTags('', '2.5D', 'Serious Game', 'Point & Click', 'Japanese', 'Hiragana', 'Drawing', 'Gesture recognition'),
        platforms: getLabels('',
          platforms.windows,
        ),
        devices: getLabels('',
          devices.mouse,
        ),
        languages: getFlags('',
          languages.german,
        ),
        summary: 'Hiramon is a game about learning Hiragana, one of the three writing systems in Japanese. The game has a similar setting to Pokémon. The educational goal of this game is to learn all Hiragana by learning and practicing them in battles where the player has to redraw shown Hiragana with the mouse.',
        description:
          <div>
            After learning Unity in my second Bachelor's semester with a small project {a('Jumping Ball', rootURL + '/projects/games/JumpingBall')} we programmed an {b('educational game')}. This game was made in my third Bachelor's semester for the application subject called "Serious Games". This subject was a combination of Computer Science and Psychology.
            We first started to find interesting new game ideas by researching papers and the internet and talked about the ideas in a meeting session. There were 12 participants in total in the subject "Serious Games" and teams with 3-4 people were grouped together based on the ideas we most liked.
            I decided to join a group that wanted to make a game to learn {b('Hiragana')}, one of the three writing systems in {b('Japanese')}. I am learning Japanese in my free time and was also taking courses in Japanese in university so the idea was fitting well in my schedule. I also was happy to see that there were other students interested in the Japanese language.{br}

            In the game, the user has to navigate a player with the mouse. The player meets a sensei (a teacher) that teaches the player their first Hiragana. In order to learn them, the sensei shows a {b('template')} to the player with the image of the Hiragana and some arrows that indicate the stroke order.
            The player has to {b('redraw the Hiragana')} in the correct stroke order to master it. Technically, the user draws a gesture with a mouse that is recognized by the software. We decided to use the {b('$N-Protractor Recognizer')} algorithm for {b('gesture recognition')}.
            After the player learned some Hiragana, he is ready to explore the world and "fight" with other people. Similar to {u('Pokémon')}, the player has to {b('fight other Hiragana learners')} (NPCs) when he is in their line of sight.
            The fight is very similar to learning Hiragana with the sensei. The Hiragana to draw is shown first in text form, after that it has to be redrawn but {b('without any template')} that shows the Hiragana and the stroke order. The player has 6 attempts to fail during the fight and only a limited amount of time per drawing.
            A usual fight consists of drawing 3-5 Hiragana. If the player manages to redraw all Hiragana in less than 6 attempts in the given time the player wins the fight, otherwise the fight is lost.{br}

            I still like this idea and can imagine working on it further as a real game for learning Hiragana. There are some games that focus on learning Hiragana but they are missing the drawing part.
            In 2020, the free language learning app {u('Duolingo')}, however, added a mode to learn Hiragana and Katakana. Like in our application, the corresponding Hiragana strokes have to be redrawn.
            Duolingo also has exercises to draw single Hiragana strokes or draw a full Hiragana without a template. Also, a sound of the Hiragana to draw is played to let the user know how the Hiragana is pronounced before drawing it.
            I think this app is a good inspiration to develop this game further.
          </div>
      }
    },
    {
      name: 'Jumping Ball',
      year: 2015,
      comment: 'University Project',
      youtube: 'yDy4Ssgk9hA',
      entries:
      {
        team: getLabels('basic',
          { name: 'Dietmar Puschmann', detail: 'Programmer, Designer' },
          { name: 'Raymond Walter', detail: 'Programmer' },
          { name: 'Mima Maleki', detail: 'Programmer, Designer' },
        ),
        links: getLabels('basic',
          { name: 'GitHub', href: 'https://github.com/Cathalus/serious_games-ballgame' },
        ),
        technologies: getLabels('',
          technologies.unity,
          technologies.cSharp,
        ),
        tags: getTags('', '3D', 'Platformer', 'Action', 'Puzzle'),
        platforms: getLabels('',
          platforms.windows,
        ),
        devices: getLabels('',
          devices.gamepad,
        ),
        languages: getFlags('',
          languages.english,
        ),
        summary: 'Jumping Ball is a platformer where the player controls a ball that can jump. The goal is to get through all 7 levels.',
        description:
          <div>
            In the second semester after I started to study Media Computer Science we learned about the {a(technologies.unity.name, technologies.unity.link)} Game Engine by working on a small project. This project was a preparation for a bigger project we made later for the same subject. We were free to program any game we wanted.
            This was the only course with Unity available and limited to 12 students so I immediately registered for the course because I was highly interested to learn Unity after making games with {a(technologies.gameMaker.name, technologies.gameMaker.link)} and then with {a(technologies.java.name, technologies.java.link)}.
            I also have to say that this course was really fun and I still have contact with some of the students I was in this course! This course was also great to learn how to write scientific articles and also the first project I used {b('version control')}.
            For Jumping Ball we were not told to use any specific version control system so we have just chosen GitHub.{br}

            The main inspiration for the game was the game {u('Super Monkey Ball')} which is about monkeys that are inside balls, collect bananas, and have to reach the goal. The Ball in "Super Monkey Ball" can't directly be moved, instead, the whole stage is rotated. In our game, however, the Ball is moved directly.
            Like in Super Monkey Ball, the player has to navigate through different {b('platforming levels')} with several collectible items. The levels {b('must be completed within a certain time')} and the player is required to collect several Keys to advance to the next level.
            The games I played for Super Monkey Ball were designed to be played with a gamepad. So we choose to control the ball in our game with a gamepad, too. One thing that is different in our game than in Super Monkey Ball is that there are red keys that the player has to collect first before he wins by touching the goal.
            The goal is shown in red until all keys in a level have been collected. After that, the {b('goal is green')}. This indicates that the player wins when he is on the goal platform. There are also blue jumping platforms and yellow wind platforms that make the ball float in later levels.{br}

            We programmed the game by sitting together in front of one computer finding out how to code what we wanted in Unity after we designed the levels. There was some time I didn't program and was designing the levels. We later reunited to tell each other what we made.
            After all 7 levels were finished we fixed bugs and gave the game a final touch by integrating things that are not necessary, but make the game feel better, like automatically rotating the ball and a jump to the air after the goal is reached.
          </div>
      }
    },
    {
      name: 'Primitives',
      year: 2014,
      comment: 'Game++ Community Challenge #4',
      youtube: 'xbA5OeOfs34',
      entries:
      {
        team: getLabels('basic',
          { name: 'Dietmar Puschmann', detail: 'Everything' },
        ),
        links: getLabels('basic',
          { name: 'Download', href: 'https://www.dropbox.com/s/sqvm9fmjm6r7x5t/Primitives.zip?dl=0' }
        ),
        technologies: getLabels('',
          technologies.gameMakerStudio,
        ),
        tags: getTags('', '2D', 'Procedural Content Generation', 'Survival', 'Side-Scroller', 'Rogue-lite', 'Multiplayer'),
        platforms: getLabels('',
          platforms.windows,
        ),
        devices: getLabels('',
          devices.keyboard,
          devices.mouse,
        ),
        languages: getFlags('',
          languages.english,
        ),
        summary: 'Primitives is a game with procedural graphics based on primitive shapes (squares, rectangles, circles...). It takes place in a procedural generated world that is destroyed and created slowly by darkness. The goal is to survive as long as possible from spawning zombies that get slowly stronger.',
        description:
          <div>
            Like {a('Pong!', rootURL + '/projects/games/Pong!')} and {a('Cave Combat', rootURL + '/projects/games/CaveCombat')} this game was originally made for one of the game jams by the YouTuber {a('Tom Bleek', 'https://www.youtube.com/LetsGameDev')} (formerly: LetsGameDev) called "Game++ Community Challenge #4". The theme of this jam was to program a {u('Multiplayer')} game.
            It didn't matter if the Multiplayer game was local or online. I already integrated local Multiplayer in {a('Pong!', rootURL + '/projects/games/Pong!')} so I chose to make an {b('online Multiplayer')} game because I never made an online Multiplayer game and wanted to learn it.
            For this jam, I used Game Maker again, but a newer version called {a('Game Maker Studio', technologies.gameMakerStudio.link)} which doesn't bundle all assets into one file. This is a great thing, especially when using version control (which I didn't use at that time).{br}

            I was tired of creating so many custom graphics in my last project {a('Cave Combat', rootURL + '/projects/games/CaveCombat')}, so I decided to {b('create all the graphics procedurally by code')}.
            Game Maker has functions to draw simple shapes like squares, rectangles, and circles so I created the whole player, weapons (arrows), and environment (trees, bushes, rocks) with them.
            The enemies in the game (zombies) use the same shapes as the player. Their shapes are just recolored to distinguish them from the player. It is possible to kill them. They have health and their health can be reduced when they are hit by an arrow.
            The player can throw arrows by pressing the left mouse button and when a zombie was successfully killed the points score increases.
            There are {b('3 types of zombies (normal, fast, tank)')}. Fast zombies move rapidly toward the player and tank zombies endure a lot of arrows.
            All types of {b('zombies get slowly stronger over time')} (they get more health). Also, {b('more zombies spawn')} the longer the game runs.
            There comes a point when the zombies can no longer be stopped, but with more players, more arrows can be thrown and a higher {b('high score')} can be achieved!{br}

            The game world is procedurally generated. The {b('surface')} is defined by a {b('1D Perlin Noise function')}. The result of the noise function (y-position) is multiplied by some factor to flatten the terrain.
            I didn't want to make the terrain too flat though, I wanted some higher hills as well. But one problem with high hills is that the player and zombies do not come up by walking alone. To overcome this issue I allowed the player and zombies to {b('jump')}. Zombies jump automatically {b('whenever the slope is too high')}.
            The world always has a fixed width but is still changing by destroying old parts of the world on the left side and creating new parts on the right side. In order to hide this process from the user the world is dark on the left and right sides.
            Also, the spawning of zombies happens in the {b('darkness')} on the right side of the world. If the player goes to the darkness the game ends, too. The darkness moves slowly from left to right. Because of that, the player can't stay in one place for too long and has to move forward.
            I was inspired by the game {u('Magicite')} to add the procedural concept that it is not allowed to stay for too long in a procedurally generated world.{br}

            I was able to implement online Multiplayer partly for this game. Multiple players can connect to one world. The connecting {b('port is always 7777')} (This is a popular game port that is used by Terraria by default for example).
            Make sure this port is not already used and forwarded by your router or use Hamachi. Another way to test out Multiplayer is to start two instances of the game and host on one instance and join 127.0.0.1 (localhost) on the other.
            There seems, however, to be a long-term problem for joining players: The longer the game runs for joined players, the slower the game gets. Memory leaks might probably be the issue.{br}
            
            All in all, I am still quite happy with how this game turned out! As for the future, I can imagine adding a few more things that Magicite also has, like adding items, interacting with the environment (fell wood, mine stone), a crafting system, or even friendly NPCs!
            I was also thinking to add some flying enemies (maybe even dinosaurs!), different environments and bosses to make the game more interesting. Also, the point score has to be updated to receive more points depending on how strong the enemy is.
            Technically, memory leaks and Multiplayer have to receive an update. Two or more players might trade resources, and get better weapons together to make it to the final level of the game!
          </div>
      }
    },
    {
      name: 'Cave Combat',
      year: [2014, 2021],
      comment: 'Game++ Community Challenge #2',
      upcoming: true,
      youtube: 'WxeyEOZFq_w',
      entries:
      {
        team: getLabels('basic',
          { name: 'Dietmar Puschmann', detail: 'Everything' },
        ),
        links: getLabels('basic',
          { name: 'Facebook', href: 'https://www.facebook.com/CaveCombat' },
          { name: 'Accidental Noise Library', href: 'http://accidentalnoise.sourceforge.net/minecraftworlds.html' },
          { name: 'Water Physics (Blog Entry)', href: 'https://w-shadow.com/blog/2009/09/01/simple-fluid-simulation/' },
        ),
        technologies: getLabels('',
          technologies.java,
          technologies.unity,
          technologies.photoshop,
        ),
        tags: getTags('', '2D', 'Procedural Content Generation', 'Survival', 'Battle Royale', 'Terraria-like'),
        platforms: getLabels('',
          platforms.windows,
        ),
        devices: getLabels('',
          devices.keyboard,
          devices.mouse,
        ),
        languages: getFlags('',
          languages.english,
        ),
        summary: 'Cave Combat is a Battle Royale game played in a procedurally generated world. All players start on the surface and go into the caves where more loot and weapons to win the battle await.',
        description:
          <div>
            As huge a fan of {u('Terraria')}, and in general games that use procedural content generation, I also have my own game in which a world is procedurally generated.
            Like {a('Pong!', rootURL + '/projects/games/Pong!')}, this game was originally made for one of the game jams by the YouTuber {a('Tom Bleek', 'https://www.youtube.com/LetsGameDev')} (formerly: LetsGameDev) called "Game++ Community Challenge #2". The theme of this jam was to program a {u('tile-based game')}. I chose {a(technologies.java.name, technologies.java.link)} as the language to program this game because I recently learned it by watching a YouTuber called {a('Brotcrunsher', 'https://www.youtube.com/c/Brotcrunsher')}.
            Back then, I presented my game in his Java forum, but sadly the forum does not exist anymore. However, I created a Facebook page that still contains most of the images shared in the forum.
            The reason why Java fascinated me was probably that I was highly inspired by {u('Minecraft')} (which was made with Java) during that time and the similarity to C#, which the Game Engine Unity that I wanted to learn in the future, uses. I also knew that Java was thought at University, so there were 3 reasons for me to learn Java.
            And on top of that, I chose {a('Slick2D', 'https://slick.ninjacave.com/')} (a library wrapped around {a('LWJGL', 'https://www.lwjgl.org/')} for 2D games) as game library because Minecraft was programmed using LWJGL, too.{br}

            I used a Java wrapper called {a('Joise', 'https://github.com/SudoPlayGames/Joise')} for the {a('Accidental Noise Library', 'http://accidentalnoise.sourceforge.net/minecraftworlds.html')} for generating Perlin noise and other forms of {b('noise')} in a modular fashion. The results turned out very good and I highly can recommend this library and the website to learn about generating noise.
            Once the main layout of the world is generated, I {b('iterate over the world tiles')} and place trees, {a('water', 'https://w-shadow.com/blog/2009/09/01/simple-fluid-simulation/')}, mushrooms, vines, chests, and other stuff in the corresponding z-layer.
            There are also different {b('ore types (stone, copper, silver, gold)')} and {b('torches')} that can be placed. Both were coded by a modified version of the {b('flood fill algorithm')}.{br}

            I was using {a(technologies.photoshop.name, technologies.photoshop.link)} a lot in school in 2014 so I made some Pixelart graphics for this game. All images and animations shown in the trailer are custom and highly inspired by Terraria.
            After the game jam was over, I worked a little bit more on the game and changed the graphics to have a higher resolution. For the future, I might choose an art style in between because it is harder to make so many high-quality images.{br}

            I paused the development of this game in 2014 because I started to study but I still had high potential for this game. Because of that, I {b('remade')} - years later in 2021 - the whole game {b('in Unity')}.
            I found a {a('C# wrapper for the Accidental Noise Library', 'https://github.com/TinkerWorX/AccidentalNoiseLibrary')} and the game now includes all that is shown in the trailer (even more things like lava) and is running faster than ever before! (I still want to make the generation even faster though with GPU power).
            I will most likely continue this project from time to time because it is one of my favorites.
          </div>
      }
    },
    {
      name: 'Pong!',
      year: [2014, 2018],
      comment: 'Game++ Community Challenge #1',
      youtube: 'h_UjnK73eqY',
      entries:
      {
        team: getLabels('basic',
          { name: 'Dietmar Puschmann', detail: 'Everything' },
        ),
        links: getLabels('basic',
          { name: 'Download', href: 'http://www.mediafire.com/file/rdbfxjgzt55tfmc/' }
        ),
        technologies: getLabels('',
          technologies.gameMaker,
        ),
        tags: getTags('', '2D', 'Table Tennis', 'Casual Game', 'Arcade', 'Retro', 'PvP', 'PvE', 'Classic', 'Local Multiplayer'),
        platforms: getLabels('',
          platforms.windows,
        ),
        devices: getLabels('',
          devices.keyboard,
          devices.xbox360,
        ),
        languages: getFlags('',
          languages.english,
        ),
        summary: 'Pong! is my own remake of the classic game Pong. Play against the computer or locally with a friend casual Pong or play extra modes (Falling shapes, Tetris, or Snake).',
        description:
          <div>
            This game was not only super fun to make, but is super fun to play! Believe me, I and one of my friends got addicted to this game and we played this game for much longer than the time I needed to program this game.
            For the best experience, play this game together with a friend and with a Gamepad, we used an {b('Xbox 360 Controller')}.
            The game was originally made for a small game jam called "Game++ Community Challenge #1" from the YouTuber {a('Tom Bleek', 'https://www.youtube.com/LetsGameDev')} (formerly: LetsGameDev) in which everyone had to recreate the game "{u('Pong')}".
            We were allowed to use any programming language or Engine we wanted. I previously worked a lot in {a(technologies.gameMaker.name, technologies.gameMaker.link)} on a Zelda Fangame, and for this reason, I used this engine for my game.
            Game Maker is an engine for 2D games and comes with its own language called {b('Game Maker Language')} (GML) which is very beginner friendly for new programmers. Every variable is public and instead of classes, there are objects with some predefined events.
            Although this language is a bit limited, it is {b('great for beginner programmers')} and has a lot of concepts that are used in usual programming languages, too.
            I learned programming together with game development with Game Maker and GML and I can highly recommend it for beginners before switching to a usual language like Java. I also highly recommend {b('recreating something simple')} to get better at programming, like Pong!{br}

            I recreated Pong 2014 without changing the gameplay. The only difference is that the paddles slightly rotate when moving up- or downwards to give the player more control and to make the game a little bit more exciting.
            However, it is much more effective to hit the ball with the edge of the paddle to control how it moves to the player.
            Because I and my friend played the game so much {b('we named the shots')} after the ball collided with a paddle {b('depending on the new angle')} the ball got.
            Because it depends on the new angle and how long it takes the ball to reach the other player we named the shots accordingly:
            A shot is called {b('Around the World')} when the angle is bigger than 70 degrees and {b('Around the Universe/Galaxy')} when it is bigger than 83 degrees. A horizontal Shot (0 degrees) is called {b('Torpedo')}. In the settings, the amount of these shots can be enabled to display them in the HUD.{br}

            Later, in 2018, I decided to update this game and added some {b('optional settings')} to the game. I added {b('Computer vs. Computer')} which is interesting to see when the computer is in "Impossible" mode and its y-coordinate is always the same as the y-coordinate of the ball.
            Once I added this feature, it was much easier to test new stuff without playing the game itself. So I added {b('optional modes')} that instantiate things into the stages.
            One mode spawns {b('falling shapes')} that are destroyed when the ball hits the shape. However, the ball turns in the other direction when that happens!
            Another mode spawns a full {b('Tetris game')} that is played by the computer in the Pong stage. Tetris Blocks are destroyed when the ball hits the shape, too.
            The last mode spawns a full {b('snake game')} that is played by the computer in the Pong stage. The Ball can't destroy the Snake, but when the Snake hits itself the Snake is destroyed entirely.
            In the settings, there is an option in which modes should appear in the game randomly after some time and also a {b('Random Mode')} which is pretty crazy because it randomizes almost everything (time between events, amount/speed/size of instantiated objects).{br}

            By customizing this game even more I can imagine turning this into a really fun game. I plan to add missions, items, bosses, transformations, more events, more stages, more references to other classic games, and an adventure mode where you slowly learn new abilities... the ideas are endless!
          </div>
      }
    },
    /*{
      name: 'Echoes of Aurelia',
      year: [2012, 2017, 2021],
      comment: 'Personal Project',
      entries:
      {
        team: getLabels('basic',
          { name: 'Dietmar Puschmann', detail: 'Programmer' },
          { name: '+some more', detail: 'Pixel Artists, Musician' },
        ),
        links: getLabels('basic',
          { name: 'Video', href: 'https://www.youtube.com/watch?v=MXuPWnlcbPI' }
        ),
        technologies: getLabels('',
          technologies.gameMaker,
          technologies.unity,
        ),
        tags: getTags('', '2D', 'Action-Adventure', 'Puzzle', 'Singleplayer', 'Multiplayer' ),
        platforms: getLabels('',
          platforms.windows,
        ),
        devices: getLabels('',
          devices.keyboard,
          devices.gamepad,
        ),
        languages: getFlags('',
          languages.english,
        ),
        summary: 'A project I am recreating all the time. Hopefully it will be an original game one day.',
        description:
          <div>
            Although this is my biggest project by far, the project I learned programming with, and the project I spend the most time in, this project will never be released to the public because it is a Zelda Fangame.
            I am working on this project from time to time trying to improve my programming skills and to learn new design patterns. It kind of became a passion to work on this project to me.
          </div>
      }
    },*/
  ]},
  { name: 'Websites', panes: [
    {
      name: 'Portfolio',
      year: 2022,
      comment: 'This Website',
      gallery: {
        items: [
          { original: image1Portfolio, originalHeight: originalHeightGallery },
          { original: image2Portfolio, originalHeight: originalHeightGallery },
          { original: image3Portfolio, originalHeight: originalHeightGallery },
          { original: image4Portfolio, originalHeight: originalHeightGallery },
        ],
      },
      entries:
      {
        team: getLabels('basic',
          { name: 'Dietmar Puschmann', detail: 'Everything' },
        ),
        technologies: getLabels('',
          technologies.react,
          technologies.typeScript,
          technologies.html5,
          technologies.css3,
          technologies.php,
          technologies.semanticUI,
          technologies.webpack,
        ),
        tags: getTags('', 'Personal Website', 'Presentation', 'Hiring'),
        browsers: getLabels('',
          browsers.chrome,
          browsers.safari,
          browsers.edge,
          browsers.firefox,
          browsers.opera,
        ),
        languages: getFlags('',
          languages.english,
        ),
        description:
          <div>
            Coming Soon
            {/*Name used NPM Packages*/}
          </div>
      }
    },
    {
      name: 'MailVis',
      year: { start: 2020, end: 2021 },
      comment: 'University Project',
      youtube: 'xszfogMTg-w',
      entries:
      {
        team: getLabels('basic',
          { name: 'Fabian Henkel', detail: 'Programmer' },
          { name: 'Dietmar Puschmann', detail: 'Programmer' },
        ),
        links: getLabels('basic',
          { name: 'Website', href: 'https://mailvis.dietmarpuschmann.com' },
          { name: 'Presentation', href: 'https://drive.google.com/file/d/1rahev1zovCUva4F0bI-wJla0gPYz1Mq1/view' },
        ),
        technologies: getLabels('',
          technologies.javaScript,
          technologies.html5,
          technologies.css3,
          technologies.bootstrap,
          technologies.d3,
          technologies.browserify,
          technologies.jekyll,
        ),
        tags: getTags('', 'Web Extension', 'Chrome', 'Gmail', 'Visualizations'),
        browsers: getLabels('',
          browsers.chrome,
        ),
        languages: getFlags('',
          languages.english,
        ),
        summary: 'MailVis is a Gmail Web Extension for Chrome that improves the user\'s workflow. The extension adds visualizations to Gmail. The email traffic of an account can be visualized as Bar Chart or Tree Map. Email Threads can be visualized with a Git Graph, Word Cloud, or Dependency Graph.',
        description:
          <div>
            Coming Soon. A detailed description of the project can be read on the {a('project website', 'https://mailvis.dietmarpuschmann.com')}.
          </div>
      }
    },
    {
      name: 'Perfect Pitch Training',
      year: 2021,
      comment: 'Personal Project',
      upcoming: true,
      gallery: {
        items: [
          { original: image1PerfectPitchTraining },
          { original: image2PerfectPitchTraining },
          { original: image3PerfectPitchTraining },
        ],
      },
      entries:
      {
        team: getLabels('basic',
          { name: 'Dietmar Puschmann', detail: 'Everything' },
        ),
        links: getLabels('basic',
          { name: 'Website', href: 'https://depuschm.github.io/pitch-training/' },
          { name: 'GitHub', href: 'https://github.com/depuschm/pitch-training' },
        ),
        technologies: getLabels('',
          technologies.javaScript,
          technologies.html5,
          technologies.css3,
        ),
        tags: getTags('', 'Education', 'Music', 'YouTube', 'Piano Roll'),
        browsers: getLabels('',
          browsers.chrome,
          browsers.safari,
          browsers.edge,
          browsers.firefox,
          browsers.opera,
        ),
        languages: getFlags('',
          languages.english,
        ),
        summary: 'Perfect Pitch Training is a website to learn how to recognize the pitch of a note better by associating keys with music pieces. The website has an interactive virtual piano. After a key is clicked, piano roll YouTube videos are displayed below showing a known melody of a song that starts with the clicked key. Users can use the default video collection or create their own video collection by uploading a JSON file.',
        description:
          <div>
            Coming Soon
            {/*TODO: MIDI Keyboard support "press key" instead of "click key"*/}
          </div>
      }
    },
  ]},
  { name: 'Software', defaultActiveIndex: 2, panes: [
    {
      name: 'Piano Fingering App',
      year: { start: 2022, end: 2023 },
      comment: 'Master Thesis',
      upcoming: true,
      gallery: {
        items: [
          { original: image1PianoFingeringApp, originalHeight: originalHeightGallery },
          { original: image2PianoFingeringApp, originalHeight: originalHeightGallery },
          { original: image3PianoFingeringApp, originalHeight: originalHeightGallery },
        ],
      },
      entries:
      {
        team: getLabels('basic',
          { name: 'Dietmar Puschmann', detail: 'Everything' },
        ),
        technologies: getLabels('',
          technologies.unity,
          technologies.cSharp,
          technologies.vuforia,
          technologies.mediaPipe,
          technologies.openCV,
          technologies.museScore,
        ),
        tags: getTags('', 'Education', 'Piano Fingering', 'AR', 'AI', 'Marker'),
        platforms: getLabels('',
          platforms.windows,
          platforms.android,
        ),
        devices: getLabels('',
          devices.laptop,
          devices.tablet,
          devices.camera,
        ),
        languages: getFlags('',
          languages.english,
        ),
        summary: 'Piano Fingering App is an app to learn piano fingering. The app will automatically watch the hands of the pianist with a Webcam from the top and display any finger errors on the app while playing.',
        description:
          <div>
            Coming Soon
            {/*The app will support 3 ways of piano fingering mapping: text, image and audio.*/}
          </div>
      }
    },
    {
      name: 'ViGaTu',
      year: { start: 2020, end: 2023 },
      comment: 'University Job',
      video: 'https://www.endoscopy-campus.com/wp-content/uploads/vigatu2_deutsch_final.mp4',
      entries:
      {
        team: getLabels('basic',
          { name: 'Julian Kreiser', detail: 'Team Leader' },
          { name: 'Dietmar Puschmann', detail: 'Programmer' },
          { name: '+many others', detail: 'Programmer' },
        ),
        links: getLabels('basic',
          { name: 'Website', href: 'https://www.ukw.de/research/inexen/vigatu/' },
        ),
        technologies: getLabels('',
          technologies.unity,
          technologies.cSharp,
        ),
        tags: getTags('', 'Education', 'VR', 'Simulation', 'Endoscopy'),
        platforms: getLabels('',
          platforms.android,
        ),
        devices: getLabels('',
          devices.smartphone,
          devices.oculusQuest,
        ),
        languages: getFlags('',
          languages.english,
          languages.german
        ),
        summary: 'ViGaTu - Short for "Virtual Gastro Tutor" is an educational VR system to learn how to perform a guideline-compliant preventive colonoscopy. The project is funded by the German Federal Ministry of Education and Research (BMBF).',
        description:
          <div>
            Coming Soon
            {/*General Information https://www.researchgate.net/project/VIGATU-Virtual-Gastro-Tutor + Own Experience*/}
          </div>
      }
    },
    {
      name: 'SpARklingPaper',
      year: { start: 2019, end: 2021 },
      comment: 'University Project',
      youtube: 'iYGCJtrQIZY',
      entries:
      {
        team: getLabels('basic',
          { name: 'Tobias Drey', detail: 'Project supervisor' },
          { name: 'Dietmar Puschmann', detail: 'Programmer' },
          { name: '+some others', detail: 'Programmer' },
        ),
        links: getLabels('basic',
          { name: 'Paper (DOI)', href: 'https://doi.org/10.1145/3550337' },
          { name: 'Paper (Read Online)', href: 'https://drive.google.com/file/d/17XsFQ7JMDojXiEKGNhFdnRbfJmmcWdcK/view' },
        ),
        technologies: getLabels('',
          technologies.unity,
          technologies.cSharp,
          technologies.vuforia,
          technologies.openCV,
        ),
        tags: getTags('', 'Education', 'Writing', 'AR', 'Marker'),
        platforms: getLabels('',
          platforms.android,
          platforms.iOS,
        ),
        devices: getLabels('',
          devices.tablet,
          devices.smartphone,
          devices.camera,
          devices.penAndPaper,
        ),
        languages: getFlags('',
          languages.english,
          languages.german
        ),
        summary: 'SpARklingPaper is a system combining a pen, paper, and tablet. The user can write traditionally on paper with a pen and information is augmented from below with a tablet. The app running on the tablet was designed for writing novices. In exercises, novices write letters on paper over the tablet. The system needs an additional smartphone that records from the top to detect the letter written on the paper. This information is sent wirelessly to the tablet to show what parts the user wrote wrong.',
        description:
          <div>
            Coming Soon
          </div>
      }
    },
  ]},
  { name: 'Art', defaultActiveIndex: 1, panes: [
    {
      name: 'Landscape Photography',
      year: 2013,
      comment: 'School Project',
      gallery: {
        showBullets: false,
        items: [
          { thumbnail: image1LandscapePhotography, original: image1LandscapePhotography, originalHeight: originalHeightGallery },
          { thumbnail: image2LandscapePhotography, original: image2LandscapePhotography, originalHeight: originalHeightGallery },
          { thumbnail: image3LandscapePhotography, original: image3LandscapePhotography, originalHeight: originalHeightGallery },
          { thumbnail: image4LandscapePhotography, original: image4LandscapePhotography, originalHeight: originalHeightGallery },
          { thumbnail: image5LandscapePhotography, original: image5LandscapePhotography, originalHeight: originalHeightGallery },
          { thumbnail: image6LandscapePhotography, original: image6LandscapePhotography, originalHeight: originalHeightGallery },
        ],
      },
      entries:
      {
        team: getLabels('basic',
          { name: 'Dietmar Puschmann', detail: 'Everything' },
        ),
        technologies: getLabels('',
          technologies.illustrator,
        ),
        utensils: getLabels('',
          devices.penAndPaper,
        ),
        tags: getTags('', 'Logo', 'Design', 'Photography', 'Landscape'),
        summary: 'Logo designs and final results for a fictional company called "Landscape Photography".',
      }
    },
    {
      name: 'Fanart',
      year: 2012,
      comment: 'Personal Project',
      gallery: {
        showBullets: false,
        items: [
          { thumbnail: image1Fanart, original: image1Fanart, originalHeight: originalHeightGallery },
          { thumbnail: image2Fanart, original: image2Fanart, originalHeight: originalHeightGallery },
          { thumbnail: image3Fanart, original: image3Fanart, originalHeight: originalHeightGallery },
          { thumbnail: image4Fanart, original: image4Fanart, originalHeight: originalHeightGallery },
          { thumbnail: image5Fanart, original: image5Fanart, originalHeight: originalHeightGallery },
          { thumbnail: image6Fanart, original: image6Fanart, originalHeight: originalHeightGallery },
          { thumbnail: image7Fanart, original: image7Fanart, originalHeight: originalHeightGallery },
        ],
      },
      entries:
      {
        team: getLabels('basic',
          { name: 'Dietmar Puschmann', detail: 'Everything' },
        ),
        references: getLabels('basic',
          { name: 'Dovahkiin (Skyrim)', href: 'https://static.wikia.nocookie.net/elderscrolls/images/4/4f/Dovahkiin_(dragonborn).jpg/revision/latest' },
          { name: 'Link (Zelda: Wind Waker)', href: 'https://pbs.twimg.com/media/DNo4JkAWkAA6wbR?format=jpg' },
          { name: 'Link (Zelda: Twilight Princess)', href: 'https://favpng.com/png_view/the-legend-of-zelda-the-legend-of-zelda-twilight-princess-hd-link-gamecube-princess-zelda-hyrule-warriors-png/jFfrr8cA' },
          { name: 'Fire Golem (Arcania)', href: 'https://www.worldofgothic.de/screenshots/gothic4_artwork/g4_golem_3alterations.jpg' },
        ),
        technologies: getLabels('',
          technologies.photoshop,
        ),
        utensils: getLabels('',
          devices.penAndPaper,
        ),
        tags: getTags('', 'Fanart', 'Characters', 'Drawings', 'Digital Art'),
        summary: 'Fanart drawings of various characters from Skyrim, Zelda, and Arcania.',
        credits: 'Credits go to The Elder Scrolls V: Skyrim (Dovahkiin), The Legend of Zelda (Wind Waker and Twilight Princess Link), and Arcania - Gothic 4 (Fire Golem). I drew, colored, and digitalized the characters, but did not draw the initial pose.',
      }
    },
  ]},
];

const showHiddenProjectFromURL = (): boolean => {
  let index = -1;
  let subIndex = -1;
  const fullPathname = window.location.pathname;
  const pane = fullPathname.split('/')[2];
  if (pane) {
    index = panes.findIndex(({ name }) => removeSpaces(name.toLowerCase()) === removeSpacesURL(pane.toLowerCase()));
    
    const subPane = fullPathname.split('/')[3];
    if (subPane && index !== -1) {
      subIndex = panes[index].panes.findIndex(({ name }) => removeSpaces(name.toLowerCase()) === removeSpacesURL(subPane.toLowerCase()));
      
      if (subIndex !== -1 && panes[index].panes[subIndex].hidden) {
        // If hidden project was entered, show it. Also remove every other hidden project.
        panes[index].panes = panes[index].panes.filter((project, index) => !project.hidden || index === subIndex);
        return true;
      }
    }
  }
  // If no hidden project was entered, remove every hidden project.
  panes.forEach((section: ProjectSection) => {
    section.panes = section.panes.filter(project => !project.hidden);
  });
  return false;
};
showHiddenProjectFromURL();

const Projects = ({basepath, basename}: {basepath: string, basename: string}) => {
  const tabRef = useRef(null);
  const subTabRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const defaultActiveIndex = panes[0].defaultActiveIndex;
  const [section, setSection] = useState(0);
  const [activeIndex, setActiveIndex] = useState(defaultActiveIndex);

  const getYearAsString = (year: number | number[] | YearRange) => {
    if (typeof year === 'number') return year;
    else if (Array.isArray(year)) return year.join(', ');
    else return year.start + ' - ' + year.end;
  };

  const onProjectSectionTabChange = (e, { activeIndex, subIndex=-1 }) => {
    if (activeIndex === -1) activeIndex = 0;
    const defaultActiveIndex = panes[activeIndex].defaultActiveIndex;
    let index = 0;
    if (subIndex !== -1) index = subIndex;
    else if (defaultActiveIndex) index = defaultActiveIndex;
    const projectSectionName = panes[activeIndex].name;
    const projectName = panes[activeIndex].panes[index].name;
    navigate(removeSpaces('/' + basepath + '/' + uncapitalize(projectSectionName) + '/' + projectName), { replace: true });
    document.title = capitalize(projectName) + ' | ' + basename;
    tabRef.current.state.activeIndex = activeIndex;
    setSection(activeIndex);
    setActiveIndex(index);
  };

  const onProjectTabChange = (e, { activeIndex }) => {
    const projectSectionName = panes[section].name;
    const projectName = panes[section].panes[activeIndex].name;
    navigate(removeSpaces('/' + basepath + '/' + uncapitalize(projectSectionName) + '/' + projectName), { replace: true });
    document.title = capitalize(projectName) + ' | ' + basename;
    setActiveIndex(activeIndex);
  };

  const navigation = (redirect: boolean) => {
    let index = tabRef.current.state.activeIndex;
    let subIndex = -1;
    if (redirect) {
      const fullPathname = location.pathname;
      const pane = fullPathname.split('/')[2];
      if (pane) {
        index = panes.findIndex(({ name }) => removeSpaces(name.toLowerCase()) === removeSpacesURL(pane.toLowerCase()));
        
        const subPane = fullPathname.split('/')[3];
        if (subPane && index !== -1) {
          subIndex = panes[index].panes.findIndex(({ name }) => removeSpaces(name.toLowerCase()) === removeSpacesURL(subPane.toLowerCase()));
        }
      }
    }
    onProjectSectionTabChange(null, { activeIndex: index, subIndex: subIndex } );
  };

  useEffect(() => {
    navigation(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Segment compact attached className="projects">
      <Tab 
        ref={tabRef}
        onTabChange={onProjectSectionTabChange}
        panes={panes.map((projectSection) => (
        { menuItem: projectSection.name, render: () => (
          <Tab.Pane>
            <Tab
              ref={subTabRef}
              menu={{ fluid: true, vertical: true, tabular: true }}
              activeIndex={activeIndex}
              onTabChange={onProjectTabChange}
              panes={projectSection.panes.map((project) => (
              { menuItem: project.name, render: () => (
                <Tab.Pane>
                  <>
                    <Header key={project.name} size="medium">
                      <>
                        {project.name}
                        {project.year && <> ({getYearAsString(project.year)})</> }
                        {project.comment && <Label content={project.comment} className="comment" basic /> }
                        {project.upcoming && <Label content="Upcoming!" color="blue" basic /> }
                      </>
                    </Header>
                    { project.youtube &&
                      <div className="video">
                        <iframe
                          key={project.youtube}
                          src={"https://www.youtube.com/embed/" + project.youtube}
                          title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen>
                        </iframe>
                      </div>
                    }
                    { project.video &&
                      <div className="video">
                        <video key={project.video} controls>
                          <source src={project.video} type="video/mp4"></source>
                        </video>
                      </div>
                    }
                    { project.image &&
                      <Image src={project.image} size="huge" centered />
                    }
                    { project.gallery &&
                      <ImageGallery
                        key={project.name + "-gallery"}
                        items={project.gallery.items}
                        showPlayButton={project.gallery.items.length > 1}
                        showBullets={project.gallery.showBullets !== false && project.gallery.items.length > 1}
                        thumbnailPosition={project.gallery.thumbnailPosition}
                        additionalClass={project.gallery.items[0].thumbnail ? "thumbnails" : ""}
                      />
                    }
                    { project.entries &&
                      <Table key={project.name + "-entries"} definition>
                        <Table.Body>
                          {Object.entries(project.entries).map((entry, index) => (
                            <Table.Row key={index}>
                              <Table.Cell className='smallCell'>{capitalize(entry[0])}</Table.Cell>
                              <Table.Cell>{entry[1]}</Table.Cell>
                            </Table.Row>
                          ))}
                        </Table.Body>
                      </Table>
                    }
                  </>
                </Tab.Pane>
              )}
            ))} />
          </Tab.Pane>
        )}
      ))} />
    </Segment>
  );
};

export default Projects;
