import { SemanticICONS } from 'semantic-ui-react';

interface Platforms {
  [key: string]: Platform
}

interface Platform {
  name: string;
  icon: SemanticICONS;
}

const platforms: Platforms = {
  windows: { name: 'Windows', icon: 'windows' },
  linux: { name: 'Linux', icon: 'linux' },
  mac: { name: 'Mac', icon: 'apple' },
  android: { name: 'Android', icon: 'android' },
  iOS: { name: 'iOS', icon: 'apple' },
  switch: { name: 'Switch', icon: 'nintendo switch' },
  playstation: { name: 'Playstation', icon: 'playstation' },
  xbox: { name: 'Xbox', icon: 'xbox' },
  webGL: { name: 'WebGL', icon: 'globe' },
  website: { name: 'Website', icon: 'globe' },
}

export default platforms;
