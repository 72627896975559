import { technologies as myTechnologies, Technologies } from './Technologies';

import imageBlender from '../images/technologies/other/design/modeling/blender-logo.png';
import imageCharacterCreator from '../images/technologies/other/design/modeling/cc-logo.png';
import imageIClone from '../images/technologies/other/design/modeling/iclone-logo.png';

const technologies: Technologies = {
  ...myTechnologies,

  // Design

  // Modeling
  blender: { name: 'Blender', image: imageBlender, link: 'https://www.blender.org/' },
  characterCreator: { name: 'Character Creator', image: imageCharacterCreator, link: 'https://www.reallusion.com/store/product.html' },
  iClone: { name: 'iClone', image: imageIClone, link: 'https://www.reallusion.com/store/product.html' },
}

export default technologies;
