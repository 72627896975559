import { FlagNameValues } from 'semantic-ui-react';

interface Languages {
  [key: string]: Language
}

interface Language {
  name: string;
  icon: FlagNameValues;
}

const languages: Languages = {
  english: { name: 'English', icon: 'us' },
  german: { name: 'German', icon: 'de' },
  spanisch: { name: 'Spanisch', icon: 'es' },
  french: { name: 'French', icon: 'fr' },
}

export default languages;
