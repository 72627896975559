import { Menu, Icon, SemanticICONS, SemanticWIDTHS } from 'semantic-ui-react';

interface SocialMediaItem {
  name: SemanticICONS;
  href: string;
}

const socialMediaItems: SocialMediaItem[] = [
  { name: 'github', href: 'https://github.com/depuschm' },
  { name: 'linkedin', href: 'https://www.linkedin.com/in/dietmar-puschmann-1110b9212' },
  { name: 'xing', href: 'https://www.xing.com/profile/Dietmar_Puschmann' },
  { name: 'twitter', href: 'https://twitter.com/depuschm' },
  { name: 'youtube', href: 'https://www.youtube.com/user/Rayotimewalker' },
  { name: 'instagram', href: 'https://www.instagram.com/dietmar.puschmann' },
];

const SocialMedia = () => {
  return (
    <Menu widths={socialMediaItems.length as SemanticWIDTHS} attached="bottom">
      {socialMediaItems.map((item, index) => (
        <Menu.Item
          key={index}
          href={item.href}
          target="_blank"
          rel="noopener"
        >
          <Icon name={item.name} size="big" />
        </Menu.Item>
      ))}
    </Menu>
  );
};

export default SocialMedia;
