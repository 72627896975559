// From: https://react.semantic-ui.com/elements/container/#types-container
//       https://stackoverflow.com/questions/47573087/typescript-check-if-value-is-contained-in-type#57065841
//       https://stackoverflow.com/questions/40863488/how-can-i-iterate-over-a-custom-literal-type-in-typescript#64174790
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Header, Container } from 'semantic-ui-react';
import { removeSpaces, removeSpacesURL } from '../ts/helper/StringUtils';
import MainMenu from './MainMenu';
import AboutMe from './AboutMe';
import Technologies from './Technologies';
import Projects from './Projects';
import Contact from './Contact';
import PageNotFound from './PageNotFound';
import SocialMedia from './SocialMedia';
import LegalNotice from './LegalNotice';
import '../css/MainContainer.css';

const basename = 'Dietmar Puschmann';
const activeItems = ['aboutMe' , 'technologies', 'projects', 'contact', 'legalNotice', 'pageNotFound'] as const;

export type ActiveItem = typeof activeItems[number];

const MainContainer = () => {
  const location = useLocation();
  const defaultActiveItem: ActiveItem = 'aboutMe';
  const [activeItem, setActiveItem] = useState<ActiveItem>(null);
  const [legalNoticeOpen, setLegalNoticeOpen] = useState<boolean>(false);

  const navigation = () => {
    const fullPathname = location.pathname;
    const menuItem = fullPathname.split('/')[1] as ActiveItem;
    const item = activeItems.find((name) => removeSpaces(name.toLowerCase()) === removeSpacesURL(menuItem.toLowerCase()));
    const index = activeItems.findIndex((name) => name === item);
    if (index === -1) {
      setActiveItem(defaultActiveItem);
    }
    else if (item === 'legalNotice') {
      setActiveItem(defaultActiveItem);
      setLegalNoticeOpen(true);
    }
    else {
      setActiveItem(item);
      setLegalNoticeOpen(false);
    }
  }

  useEffect(() => {
    navigation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Header id="welcome-message" size="huge" inverted>
        Hello, I am Dietmar!
      </Header>

      <div id="header-space"></div>
      <div id="content">
        <MainMenu activeItem={activeItem} setActiveItem={setActiveItem}/>
        {activeItem === 'aboutMe' && <AboutMe basepath={activeItem} basename={basename}/>}
        {activeItem === 'technologies' && <Technologies basepath={activeItem} basename={basename}/>}
        {activeItem === 'projects' && <Projects basepath={activeItem} basename={basename}/>}
        {activeItem === 'contact' && <Contact basepath={activeItem} basename={basename}/>}
        {activeItem === 'pageNotFound' && <PageNotFound basepath={activeItem} basename={basename}/>}
        <SocialMedia />
      </div>
      <div id="footer-space"></div>

      <LegalNotice legalNoticeOpen={legalNoticeOpen} setLegalNoticeOpen={setLegalNoticeOpen}/>
    </Container>
  );
};

export default MainContainer;
