// From: https://react.semantic-ui.com/collections/form/#shorthand-field-control-id
//       https://react.semantic-ui.com/collections/form/#usage-capture-values
//       https://stackoverflow.com/questions/38235715/fetch-reject-promise-and-catch-the-error-if-status-is-not-ok#38236296
//       https://stackoverflow.com/questions/63871283/how-to-refer-react-to-php-file-using-fetch-and-catch#63871426
//       https://stackoverflow.com/questions/29411815/is-it-possible-to-pass-null-value-via-post-get-php#29411956
//       https://stackoverflow.com/questions/28265381/regex-that-does-not-match-any-html-tag#28265592
//       https://stackoverflow.com/questions/12097651/regex-allow-everything-but-some-selected-characters#12097719
//       https://stackoverflow.com/questions/3162457/how-to-check-with-javascript-if-connection-is-local-host#46116372
//       https://www.vincecampanale.com/blog/2018/01/12/dynamically-properties-to-typescript-interfaces/
//       https://sebhastian.com/handlechange-react/
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Segment, Button, Message, Label } from 'semantic-ui-react';
import { Form, Input, TextArea, Select } from 'formsy-semantic-ui-react';
import { capitalize, removeSpaces } from '../ts/helper/StringUtils';
import '../css/ContactMe.css';

enum FormState {
  Default,
  Success,
  Error
}

interface TypeOption {
  key: string;
  text: string;
  value: string;
}

interface Group {
  name: string,
  label: string,
  control: any;
  options?: TypeOption[],
  [key: string]: any;
}

const typeOptions: TypeOption[] = [
  { key: 'm', text: 'Hiring', value: 'hiring' },
  { key: 'f', text: 'Collaboration', value: 'collaboration' },
  { key: 'o', text: 'Other', value: 'other' },
];

const noTagsRegex = /^[^<>]*$/; // All characters allowed except < and >

const groups: Group[][] = [
  [
    { name: 'firstName', label: 'First name', control: Input, placeholder: 'Joe', required: true, validations: 'isWords', validationErrors: { isWords: 'No numbers or special characters allowed', isDefaultRequiredValue: 'First Name is Required' }, instantValidation: true  },
    { name: 'lastName', label: 'Last name', control: Input, placeholder: 'Schmoe', required: true, validations: 'isWords', validationErrors: { isWords: 'No numbers or special characters allowed', isDefaultRequiredValue: 'Last Name is Required' }, instantValidation: true },
  ],
  [
    { name: 'type', label: 'Type', control: Select, options: typeOptions, required: true, validationErrors: { isDefaultRequiredValue: 'Type is Required' } },
    { name: 'email', label: 'Email', control: Input, placeholder: 'joe@schmoe.com', required: true, validations: 'isEmail', validationErrors: { isEmail: 'Please enter a valid email address',  isDefaultRequiredValue: 'Email is Required' } },
  ],
  [
    { name: 'message', label: 'Message', control: TextArea, placeholder: 'Hello, I have an interesting idea and want to collaborate with you.', required: true, validations: { matchRegexp: noTagsRegex }, validationErrors: { matchRegexp: 'No tags allowed', isDefaultRequiredValue: 'Message is Required' }, instantValidation: true },
  ],
];

const nickName = { name: 'nickName', label: 'Nick name', control: Input };

const Contact = ({basepath, basename}: {basepath: string, basename: string}) => {
  const navigate = useNavigate();
  const errorLabel = <Label pointing prompt/>
  //const recipientEmail = 'depuschm@gmail.com';
  //const recipientLink = <a href={'mailto:' + recipientEmail}>{recipientEmail}</a>;

  // Set correct sendMail path to fetch from.
  // In case this project is in a path were PHP is evaluated, set sendMailDevPath = sendMailPath.
  // In case this project is not in a path were PHP is evaluated you have to reference the folder "public/php" with sendMailDevPath
  // (use a path were all files of "public/php" and "vendor" (copied or generated by composer) are present).
  const LOCAL_DOMAINS = ['localhost', '127.0.0.1'];
  const sendMailDevPath = 'http://localhost/portfolio/sendMail.php';
  const sendMailPath = 'php/sendMail.php';
  const sendMailFetchPath = LOCAL_DOMAINS.includes(window.location.hostname) ? sendMailDevPath : sendMailPath;

  const [formValue, setFormValue] = useState({ firstName: '', lastName: '', type: '', email: '', message: '', nickName: '' });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [formState, setFormState] = useState(FormState.Default);

  const onChange = (e, { name, value }) => setFormValue((prevState) => { return { ...prevState, [name]: value }; });

  const onValidSubmit = async () => {
    const { firstName, lastName, type, email, message, nickName } = formValue;

    const formData = new FormData();
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('type', type);
    formData.append('email', email);
    formData.append('message', message);
    if (nickName.length > 0) formData.append('nickName', nickName);

    setFormLoading(true);
    const res = await fetch(sendMailFetchPath, {
      method: 'POST',
      redirect: 'follow',
      body: formData
    }).catch(error => {
      throw(error);
    });
    if (res.ok) {
      const text = await res.text();
      if (text === 'Message has been sent.') {
        setFormSubmitted(true);
        setFormState(FormState.Success);
        console.log(text);
      }
      else {
        setFormState(FormState.Error);
        console.error(text);
      }
    }
    setFormLoading(false);
    if (!res.ok) {
      setFormState(FormState.Error);
      throw new Error('Something went wrong: ' + res.status);
    }
  };

  useEffect(() => {
    navigate(removeSpaces('/' + basepath), { replace: true });
    document.title = capitalize(basepath) + ' | ' + basename;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Segment compact attached>
      <p className="reveal">Interested in working together? Send me an email {/*at {recipientLink}*/}or use the form below:</p>
      <Form success={formState === FormState.Success} error={formState === FormState.Error} noValidate onValidSubmit={onValidSubmit} loading={formLoading}>
        { !formSubmitted && groups.map((group, index) => (
          <Form.Group widths='equal' key={index}>
            {group.map((field, index) => (
              <Form.Field {...field} key={index} onChange={onChange} errorLabel={errorLabel} />
            ))}
          </Form.Group>
        ))}
        {/*Honeypot to prevent robots from spamming, https://stackoverflow.com/questions/36227376/better-honeypot-implementation-form-anti-spam */}
        <Form.Field {...nickName} className="nickName" tabIndex={-1} autoComplete="off" onChange={(e) => onChange(e, {name: e.target.name, value: 'changed'})} />

        { !formSubmitted &&<Form.Field
          control={Button}
          content="Submit"
        />}
        <Message
          success
          header="Form Submitted"
          content={'You\'re message was send.'}
        />
        <Message
          error
          header="Form Error"
          content={'Something went wrong while sending an email.'}
        />
      </Form>
    </Segment>
  );
};

export default Contact;
